import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import dummyData from '../components/sidebar-menu/dummy-data.json';
const useSidebarDummyData = () => {
    const { storeName } = useSelector(({ ui }) => ui);
    const [data, setData] = useState([]);
    useEffect(() => {
        const { sevenEleven, johnnyDeli } = dummyData;
        if (storeName !== '7-eleven') {
            setData(johnnyDeli);
        } else {
            setData(sevenEleven);
        }
    }, [storeName]);
    return data;
};

export default useSidebarDummyData;
