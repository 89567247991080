import { createSlice } from '@reduxjs/toolkit';

export const UiReducer = createSlice({
    name: 'ui',
    initialState: {
        errors: {
            testError: 'Lorem ipsum dolor sit amet consectetur.',
        },
        pathUrlHistories: [],
        storeName: 'deli',
    },
    reducers: {
        setError: (state, { payload: { name, errorMessage } }) => {
            return (state.errors[name] = errorMessage);
        },
        setPathUrlHistory: (state, { payload: { action, history } }) => {
            if (action === 'POP') {
                state.pathUrlHistories.pop();

                return state;
            }
            state.pathUrlHistories.push(history);
            return state;
        },
        setStoreName: (state, { payload }) => {
            state.storeName = payload;
            return state;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setError, setPathUrlHistory, setStoreName } = UiReducer.actions;

export default UiReducer.reducer;
