import Pagination from '@mui/material/Pagination';
import { useEffect } from 'react';

const CustomPagination = ({ setCurrentPage, ...props }) => {
    const sx = {
        position: 'absolute',
        bottom: '3rem',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };
    return (
        <Pagination
            {...props}
            sx={sx}
            shape="rounded"
            size="large"
            color="primary"
            onChange={(e, page) => setCurrentPage(page - 1)}
        />
    );
};

export default CustomPagination;
