import dateFormat from "dateformat";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(isBetween);
dayjs.extend(customParseFormat);
const Helpers = {};

Helpers.getAgentDetails = function getAgentDetails() {
    return {
        agentId: Helpers.getURLParameter("agentId"),
        agentSequence: Helpers.getURLParameter("agentSequence"),
    };
};

Helpers.getURLParameter = function getURLParameter(name, url) {
    const search = url || location.search;
    return decodeURIComponent((new RegExp(`[?|&]${name}=([^&;]+?)(&|#|;|$)`)
        .exec(search) || [null, ""])[1].replace(/\+/g, "%20")) || null;
};

Helpers.getURLRootPath = () => {
    const trimmed = location.href.split(/[?#]/)[0];
    return trimmed.substr(0, trimmed.lastIndexOf("/") + 1);
};

Helpers.hasPlaceholderIssue = () => {
    const isIE10Below = /MSIE/i.test(navigator.userAgent);
    const isIE11 = /rv:11.0/i.test(navigator.userAgent);
    return (isIE10Below || isIE11) && Helpers.isKioskApp();
};

Helpers.padZero = (date) => {
    const value = parseInt(date, 10);
    if (value <= 9) {
        return `0${value}`;
    }
    return date;
};

Helpers.parseDateResponse = (dateResponse) => {
    if (dateResponse.toString().indexOf("+") > 0) {
        const dateString = dateResponse.split("+")[0];
        return new Date(dateString);
    }
    return new Date(dateResponse);
};

Helpers.dayjsParseDate = (date, dateFormat = "DD/MM/YYYY hh:mm A") => {
    const splitDate = date.split("+");
    return dayjs(splitDate.length ? splitDate[0] : date).format(dateFormat);
};

Helpers.parseStringDateResponse = (dateResponse) => {
    if (dateResponse.indexOf(" ") > 0 && dateResponse.indexOf(":") > 0) {
        const date = new Date();
        const dateString = dateResponse.split(" ")[0];
        const year = dateString.split("-")[0];
        const month = dateString.split("-")[1];
        const day = dateString.split("-")[2];
        const timeString = dateResponse.split(" ")[1];
        const hours = timeString.split(":")[0];
        const minutes = timeString.split(":")[1];
        date.setFullYear(parseInt(year));
        date.setMonth(parseInt(month) - 1);
        date.setDate(parseInt(day));
        date.setHours(parseInt(hours));
        date.setMinutes(parseInt(minutes));
        return date;
    }
    return new Date(dateResponse);
};

Helpers.parseDate = (dateString) => {
    const date = dateString.split("-");

    if (!date.every((item) => Helpers.isNumber(item))) return null;

    if (date.length > 1 && date[2].length === 4) {
        return Helpers.parseDateDDMMYYYY(dateString);
    }
    const day = date[2] / 1;
    const month = (date[1] / 1) - 1;
    const year = date[0] / 1;
    return new Date(year, month, day);
};

Helpers.parseDateDDMMYYYY = (dateString) => {
    const date = dateString.split("-");
    const day = date[0] / 1;
    const month = (date[1] / 1) - 1;
    const year = date[2] / 1;
    return new Date(year, month, day);
};

Helpers.parseDateTime = (dateString) => {
    const parts = dateString.split(" ");

    const date = parts[0].split("-");
    const day = date[2] / 1;
    const month = (date[1] / 1) - 1;
    const year = date[0] / 1;

    const time = parts[1].split(":");
    const hour = time[0] / 1;
    const minute = time[1] / 1;
    const second = time[2] / 1;

    return new Date(year, month, day, hour, minute, second);
};

Helpers.convertObjectToArray = (object) => {
    return !Helpers.isArray(object) ? Array.from(Object.keys(object), (key) => object[key]) : object;
};

Helpers.isDateValid = (year, month, day) => {
    const invalidMonth = !month && month !== 0;
    if (!year || !day || invalidMonth) {
        return false;
    }

    if (parseInt(year) < 1900 || parseInt(day) <= 0) {
        return false;
    }

    return Helpers.isDayValid(day, month, year);
};

Helpers.isDayValid = (dayValue, month, year) => {
    if (month && year) {
        const dd = dayValue / 1;
        const mm = month / 1;
        const yy = year / 1;

        const lyear = (!(yy % 4) && yy % 100) || !(yy % 400);
        const listofDays = [31, (lyear ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

        return dd <= listofDays[mm];
    }
    return isNaN(parseInt(dayValue)) || parseInt(dayValue) <= 31;
};

Helpers.isPastDate = (year, month, day) => {
    const date = new Date(year, month, day, 0, 0, 0, 0);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    return date <= currentDate;
};

Helpers.isFutureDate = (year, month, day) => {
    const date = new Date(year, month, day, 0, 0, 0, 0);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    return date > currentDate;
};

Helpers.isBetweenDates = (inputDate, manipulateDateObj = {}) => {
    let startDate = dayjs().subtract(1, "day");
    let endDate = dayjs();
    if (Object.keys(manipulateDateObj).length) {
        const { target, operation, value, unit } = manipulateDateObj;
        if (target === "startDate") {
            startDate = startDate[operation](value, unit).set("date", 1).set("month", 0).subtract(1, "day");
        }
        if (target === "endDate") {
            endDate = endDate[operation](value, unit).set("date", 31).set("month", 12).subtract(1, "day");
        }
    }
    return dayjs(inputDate).isBetween(startDate, endDate);
};

Helpers.getAge = (year, month, day) => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();
    const currentDay = today.getDate();

    const birthYear = year / 1;
    const birthMonth = month / 1;
    const birthDay = day / 1;

    let age = currentYear - birthYear;
    const ageMonth = currentMonth - birthMonth;
    const ageDay = currentDay - birthDay;

    if (ageMonth < 0 || (ageMonth === 0 && ageDay < 0)) {
        age -= 1;
    }
    return age;
};

Helpers.toTitleCase = (value) => {
    const replaceFunction = (word) => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
    return String(value).replace(/\w\S*/g, replaceFunction);
};

Helpers.toCapitalizedWords = (value) => {
    if (typeof value === "string") {
        value = value.split(" ");

        for (var index = 0, length = value.length; index < length; index ++) {
            value[index] = value[index][0].toUpperCase() + value[index].substr(1);
        }
        return value.join(" ");
    }
    return value;
}

/* Returns array format of ordering of date parts. e.g: ["month", "day", "year"] */
Helpers.getDatePartsOrder = (format) => {
    let parts = format.split("/");
    parts = parts.length > 1 ? parts : format.split("-");

    const ordering = [];
    parts.forEach((part) => {
        const value = part.toLowerCase();
        if (value.indexOf("d") >= 0) {
            ordering.push("day");
        } else if (value.indexOf("m") >= 0) {
            ordering.push("month");
        } else if (value.indexOf("y") >= 0) {
            ordering.push("year");
        }
    });
    return ordering;
};

Helpers.formatDateInputs = (dayComponent, monthComponent, yearComponent, format) => {
    const orderedInputs = [];
    Helpers.getDatePartsOrder(format).forEach((part) => {
        if (part === "day") {
            orderedInputs.push(dayComponent);
        } else if (part === "month") {
            orderedInputs.push(monthComponent);
        } else if (part === "year") {
            orderedInputs.push(yearComponent);
        }
    });
    return orderedInputs;
};

Helpers.getElementsByTagName = (tagName) => {
    const domResult = document.getElementsByTagName(tagName);
    const elements = [];

    if (length.length !== 0) {
        for (let i = 0; i < domResult.length; i += 1) {
            elements.push(domResult[i]);
        }
    }
    return elements;
};

Helpers.setCaretToEnd = (id) => {
    const element = document.getElementById(id);
    if (element && Helpers.isKioskApp()) {
        const value = element.value;
        element.value = null;
        element.value = value;
    }
};

Helpers.setCaretPosition = (id, index) => {
    const elem = document.getElementById(id);
    setTimeout(() => {
        if (elem != null) {
            if (elem.createTextRange) {
                const range = elem.createTextRange();
                range.move('character', index);
                range.select();
            } else {
                if (elem.selectionStart) {
                    elem.focus();
                    elem.setSelectionRange(index, index);
                } else {
                    elem.focus();
                }
            }
        }
    }, 0);
};

Helpers.getTime = (arg) => {
    const date = new Date(arg);
    const h = date.getHours();
    const m = date.getMinutes();

    const hours = h > 12 ? `${h - 12}` : `${h}`;
    const minutes = m > 10 ? `${m}` : `0${m}`;
    const suffix = h > 12 ? "PM" : "AM";

    return `${h === 0 ? 12 : hours}:${minutes} ${suffix}`;
};

Helpers.formatDate = (value, argsFormat, dontReplaceCharacter) => {
    if (value) {
        const encrypted = typeof value === "string" && (value.indexOf("*") > -1 || value.indexOf("NaN") > -1);
        let date = encrypted ? new Date() : value;
        date = typeof date === "string" ? Helpers.parseDate(value) : date;
        let format = argsFormat || "dd/MM/yyyy";


        if (!dontReplaceCharacter) {
            /* dateFormat library uses M as minute and m as month. This works around it */
            const dummy = "~";
            format = format.replace(new RegExp("M", "g"), dummy);
            format = format.replace(new RegExp("m", "g"), "M");
            format = format.replace(new RegExp(dummy, "g"), "m");
        }

        if (encrypted) {
            const formattedDated = dateFormat(date, format);
            let encryptedDate = "";
            for (let i = 0; formattedDated.length > i; i += 1) {
                const c = isNaN(formattedDated.charAt(i)) ? formattedDated.charAt(i) : "*";
                encryptedDate = `${encryptedDate}${c}`;
            }
            return encryptedDate;
        }

        return dateFormat(date, format);
    }
    return null;
};

// accepted formats: DD-MM-YYY and YYYY-M-D
Helpers.formatDateStringArrangement = (dateString, format) => {
    const formatArray = format.split("-");
    const year = formatArray.find((item) => item.length === 4);
    const yearIndex = formatArray.indexOf(year);

    const date = dateString.split("-");
    const dateYear = date.find((item) => item.length === 4);
    const dateYearIndex = date.indexOf(dateYear);

    if (yearIndex === dateYearIndex) return dateString;
    return date.reverse().map((item, index) => formatArray[index].length === 2 ? Helpers.padZero(item): item).join("-");
}

Helpers.padZero = (date) => {
    const d = parseInt(date, 10);
    if (d <= 9) {
        return `0${d}`;
    }
    return d;
};

Helpers.getTime12HourFormat = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours %= 12;
    hours = hours || 12;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    const time = `${hours}:${minutes} ${ampm}`;
    return time;
};

Helpers.formatMoney = (value, currencyCode) => {
    let currency = currencyCode;
    if (typeof currency === "undefined") {
        currency = "";
    }

    let amount = (value / 1) * 100;

    let sign = "";
    if (amount < 0) {
        sign = "-";
        amount = Math.abs(amount);
    }

    amount = Math.round(amount);

    let trailingZero = "";
    if (amount % 100 < 1) {
        trailingZero = ".00";
    } else if (amount % 10 < 1) {
        trailingZero = "0";
    }

    return `${sign}${currency ? `${currency} ` : ""}${parseInt(amount, 10) / 100}${trailingZero}`;
};

Helpers.formatMoneyWithCommas = (value, currencyCode) => {
    let currency = currencyCode;
    if (typeof currency === "undefined") {
        currency = "";
    }

    let amount = (value / 1) * 100;

    let sign = "";
    if (amount < 0) {
        sign = "-";
        amount = Math.abs(amount);
    }

    amount = Math.round(amount);

    let trailingZero = "";
    if (amount % 100 < 1) {
        trailingZero = ".00";
    } else if (amount % 10 < 1) {
        trailingZero = "0";
    }

    const num = (parseInt(amount, 10) / 100).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')

    return `${sign}${currency ? `${currency} ` : ""}${num}${trailingZero}`;
};

Helpers.formatRate = (value, currencyCode) => {
    let currency = currencyCode;
    if (typeof currency === "undefined") {
        currency = "";
    }

    let amount = (value / 1) * 10000;

    let sign = "";
    if (amount < 0) {
        sign = "-";
        amount = Math.abs(amount);
    }

    amount = Math.round(amount);

    let trailingZero = "";
    if (amount % 10000 < 1) {
        trailingZero = ".0000";
    } else if (amount % 1000 < 1) {
        trailingZero = "000";
    } else if (amount % 100 < 1) {
        trailingZero = "00";
    } else if (amount % 10 < 1) {
        trailingZero = "0";
    }

    return `${sign}${currency ? `${currency} ` : ""}${parseInt(amount, 10) / 10000}${trailingZero}`;
};

Helpers.formatDecimal = (arg, min, max) => {
    const minDecimalPlaceCount = min || 2;
    const maxDecimalPlaceCount = max || 6;
    const parsedValue = parseFloat(arg);

    let decimalPlaceCount = Helpers.countDecimalPlaces(parsedValue);
    decimalPlaceCount = decimalPlaceCount > maxDecimalPlaceCount ? maxDecimalPlaceCount : decimalPlaceCount;
    decimalPlaceCount = decimalPlaceCount < minDecimalPlaceCount ? minDecimalPlaceCount : decimalPlaceCount;

    return parsedValue.toFixed(decimalPlaceCount);
};

Helpers.formatStringPattern = (rawValue, characterPattern, separator) => {
    if (rawValue && rawValue.toString().length > 0) {
        const value = rawValue.toString().replace(new RegExp(separator, "g"), "");
        let output = "";
        let index = 0;
        for (let i = 0; i < characterPattern.length && index < value.length; i += 1) {
            output = `${output}${value.substr(index, characterPattern[i])}`;
            if (index + characterPattern[i] < value.length) output += separator;
            index += characterPattern[i];
        }
        return `${output}${value.substr(index)}`;
    }
    return rawValue;
};

Helpers.smoothScroll = (to, duration) => {
    const scrollStep = Math.max((to - window.pageYOffset) / (duration / 15), 1);
    const scrollInterval = setInterval(() => {
        if (window.pageYOffset <= to) {
            window.scrollBy(0, scrollStep);
        } else {
            clearInterval(scrollInterval);
        }
    }, 15);
};

Helpers.isHalfRegistered = (customer) => customer.attribute7 && customer.attribute7.indexOf("- HALF") >= 0;

Helpers.getProductForHalfRegistered = (customer) => Helpers.isHalfRegistered(customer) ? customer.attribute7.replace(" - HALF", "") : null;

Helpers.getProductForHalfRegisteredWeb = (customer) => {
    const isHalfRegistered = () => customer.sourceOfRegistration && customer.sourceOfRegistration.indexOf("- HALF") >= 0;
    return isHalfRegistered(customer) ? customer.sourceOfRegistration.replace(" - HALF", "") : null;
};

Helpers.countryHasCorridorAnomaliesForCurrency = (config, countryCode, currencyCode) => {
    const countryProperties = config && config.specialCountries ? config.specialCountries.find((country) => country.code === countryCode) : null;
    const corridorAnomalies = countryProperties ? countryProperties.corridorAnomalies : null;
    let hasAnomalies = false;

    if (corridorAnomalies && corridorAnomalies.currenciesAnomalies) {
        const currenciesAnomalies = corridorAnomalies.currenciesAnomalies;
        currenciesAnomalies.forEach((currency) => {
            if (currency === currencyCode) {
                hasAnomalies = true;
            }
        });
    }
    return hasAnomalies;
};

Helpers.incompleteRegistration = (customer) => {
    const isNullOrUndefined = (value) => value === null || value === undefined;

    if (isNullOrUndefined(customer.occupation)) {
        return true;
    } else if (isNullOrUndefined(customer.birthCountry) || customer.birthCountry === "") {
        return true;
    } else if (isNullOrUndefined(customer.birthTown) || customer.birthTown === "") {
        return true;
    } else if (isNullOrUndefined(customer.country) || customer.country === "") {
        return true;
    } else if (isNullOrUndefined(customer.address1) || customer.address1 === "") {
        return true;
    } else if (isNullOrUndefined(customer.town) || customer.town === "") {
        return true;
    }
    return false;
};

/* Guarantees IE don't cache GET requests */
Helpers.addForceDisableCacheHeader = (url) => `${url}?timeStamp=, ${new Date().getTime().toString()}!`;

Helpers.countDecimalPlaces = (arg) => !isNaN(arg) ? (arg.toString().split(".")[1] || []).length : null;
Helpers.isArray = (arg) => !!arg && arg.constructor === Array;
Helpers.escapeRegExp = (value) => value.replace(/([.*+?^=!:${}()|[\]/\\])/g, "\\$1");
Helpers.isLetter = (char) => char && /[a-zA-Z]/.test(char);
Helpers.isNumber = (char) => char && !isNaN(char);
Helpers.isMobile = () => (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
Helpers.replaceSpacesWith = (string, replaceWith) => string.replace(/ /g, replaceWith);
Helpers.isKioskApp = () => {
    const root = document.getElementById("root");
    return root ? !root.classList.contains("webapp") : true;
};
Helpers.isIOS = () => {
    const root = document.getElementById("root");
    return root && root.classList.contains("ios");
};
Helpers.isAndroid = () => {
    const root = document.getElementById("root");
    return root && root.classList.contains("android");
};
Helpers.isMobileApp = () => Helpers.isIOS() || Helpers.isAndroid();
Helpers.isIE = () => (Helpers.getIEVersion() > 0);
Helpers.isMacOSX = () => (window.navigator.userAgent.indexOf("Mac OS X") >= 0);
Helpers.isSafari = () => (/^((?!chrome|android).)*safari/i.test(window.navigator.userAgent));
Helpers.isFirefox = () => (typeof InstallTrigger !== "undefined");
Helpers.isEdge = () => (window.navigator.userAgent.indexOf("Edge") > -1);

Helpers.getIEVersion = () => {
    const ua = window.navigator.userAgent;
    let ie = -1;

    const msie = ua.indexOf("MSIE ");
    if (msie > 0) {
        ie = parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
    }

    const trident = ua.indexOf("Trident/");
    if (trident > 0) {
        const rv = ua.indexOf("rv:");
        ie = parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
    }

    const edge = ua.indexOf("Edge/");
    if (edge > 0) {
        ie = parseInt(ua.substring(edge + 5, ua.indexOf(".", edge)), 10);
    }

    return ie;
};

const _navigator = {};
Object.defineProperty(_navigator, "ie", { value: Helpers.getIEVersion(), writable: false });
Object.defineProperty(_navigator, "firefox", { value: Helpers.isFirefox(), writable: false });
Object.defineProperty(_navigator, "safari", { value: Helpers.isSafari(), writable: false });
Object.defineProperty(_navigator, "mobile", { value: Helpers.isMobile(), writable: false });
Object.defineProperty(_navigator, "ios", { value: Helpers.isIOS(), writable: false });
Object.defineProperty(_navigator, "android", { value: Helpers.isAndroid(), writable: false });
Object.defineProperty(_navigator, "macosx", { value: Helpers.isMacOSX(), writable: false });
Object.defineProperty(_navigator, "kioskapp", { value: Helpers.isKioskApp(), writable: false });
Object.defineProperty(Helpers, "navigator", { value: _navigator, writable: false });

Helpers.getRootClassName = () => {
    let className = "ccg";
    className = `${className}${Helpers.isMobile() ? " mobile" : " non-mobile"}`;
    className = `${className}${Helpers.getIEClassName()}`;
    className = `${className}${Helpers.getSafariClassName()}`;
    className = `${className}${Helpers.isFirefox() ? " firefox" : " non-firefox"}`;
    className = `${className} ${process.env.PLATFORM}`;
    return className;
};

Helpers.getIEClassName = () => {
    const ie = Helpers.getIEVersion();
    return ie > 0 ? ` msie msie-${ie}` : " non-ie";
};

Helpers.getSafariClassName = () => {
    const macosx = Helpers.isMacOSX();
    const safari = Helpers.isSafari();
    return `${macosx ? " mac-osx" : " non-mac-osx"}${safari ? " safari" : " non-safari"}`;
};


const applyOrientation = () => {
    const windowScreen = window.screen || window;
    const root = document.getElementById("root");
    if (window.innerHeight > window.innerWidth) {
         Helpers.navigator.portrait = true;
         Helpers.navigator.landscape = false;
         Helpers.navigator.orientation = "portrait";
         if (root) {
            root.classList.add("portrait");
            root.classList.remove("landscape");
         }
    } else {
         Helpers.navigator.portrait = false;
         Helpers.navigator.landscape = true;
         Helpers.navigator.orientation = "landscape";
         if (root) {
             root.classList.remove("portrait");
             root.classList.add("landscape");
         }
    }
};

window.addEventListener("resize", applyOrientation);
window.addEventListener("load", applyOrientation);

Helpers.removeNullPropertyOfFields = (data = {}, targetProperty = "") => {
    if (Array.isArray(data) || typeof data !== "object") {
        return null;
    }

    const filter = (entry) => {
        let filterArg = entry[1] !== null;
        if (typeof entry[1] === "object") {
            filterArg = filterArg && Object.keys(entry[1]).length;
        }
        return filterArg;
    };

    const newObj = {};
    const newData = { ...data };
    const objEntries = Object.entries(targetProperty ? newData[targetProperty] : newData);
    objEntries.filter(filter).forEach(([elementKey, value]) => {
        newObj[elementKey] = value;
    });
    if (targetProperty) {
        newData[targetProperty] = newObj;
        return newData;
    }

    return newObj;
};
export { Helpers };
