import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { configRequest } from './actions/config';

import appReducer from './reducer';

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    whitelist: ['config'],
    blacklist: ['mainMenu', 'orderItems', 'productFieldPage', 'productPage'],
};

const rootReducer = (state, action) => {
    if (action?.type === 'CLEAR_STORE') {
        const { config } = state;
        state = { config };
    }
    return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const storeConfig = {
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        }),
};

export const store = configureStore(storeConfig);

function postRehydrate() {
    configRequest(store);
}
export const persistor = persistStore(store, null, postRehydrate);
