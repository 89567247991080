import { createSlice } from '@reduxjs/toolkit';
import { getNestedValue } from '../../shared/utils/helpers';

export const productFieldsSlice = createSlice({
    name: 'productFieldPage',
    initialState: {
        productFields: [],
    },
    reducers: {
        setFieldValue: (state, { payload: { name, value } }) => {
            state.productFields = state.productFields.map((product) => {
                if (product?.name === name) {
                    return { ...product, value };
                }
                return product;
            });
            return state;
        },
        setErrorMessage: (
            state,
            { payload: { name, errorMessage, hasError } },
        ) => {
            state.productFields = state.productFields.map((product) => {
                if (product?.name === name) {
                    return { ...product, errorMessage, hasError };
                }
                return product;
            });
            return state;
        },
        addProductFields: (state, { payload }) => {
            const productFieldKeys = ['productFields', 'productField'];
            const productField = getNestedValue(payload, productFieldKeys);
            const newProductFields = Array.isArray(productField)
                ? productField
                : new Array(productField);
            const productFields = newProductFields.map((el) => ({
                ...el,
                menuId: payload?.menuId,
                value: '',
                hasError: false,
                errorMessage: '',
            })); // add menuId in every productFields

            state.productFields = [...state.productFields, ...productFields];
            return state;
        },

        removeProductField: (state, { payload: menuId }) => {
            state.productFields = state.productFields.filter(
                (productField) => productField?.menuId !== menuId,
            );
            return state;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    setFieldValue,
    addProductFields,
    removeProductField,
    setErrorMessage,
} = productFieldsSlice.actions;

export default productFieldsSlice.reducer;
