import { useMemo } from 'react';
import { ButtonBase, Stack } from '@mui/material';
import BackSpaceIcon from '@mui/icons-material/Backspace';
import { button } from '../style';
import { useSelector } from 'react-redux';
const RowButtons = ({ row = [], name, setValue, onEnter, handleKeyPress }) => {
    const { enabledButtonByType, enabledButtonByValue, buttonsToBeDisabled } =
        useSelector(({ keyboard }) => keyboard);
    const memoizedRow = useMemo(() => row, [name]);

    // const handleKeyPress = (value) => {
    //     switch (value) {
    //         case 'DELETE':
    //             setValue('');
    //             break;

    //         case 'BACKSPACE':
    //             setValue((prevState) => {
    //                 const priceCopy = String(prevState);
    //                 return priceCopy.slice(0, -1);
    //             });
    //             break;
    //         case 'SPACE': {
    //             setValue((prevState) => `${prevState}${' '}`);
    //             break;
    //         }

    //         case 'ENTER':
    //             onEnter();
    //             break;
    //         default:
    //             setValue((prevState) => `${prevState}${value}`);
    //             break;
    //     }
    // };

    const setDisabledButton = (value, type) => {
        if (buttonsToBeDisabled.includes(value)) {
            return true;
        }
        if (enabledButtonByType.includes(type)) return false;
        if (enabledButtonByValue.includes(value)) {
            return false;
        }
        return true;
    };

    return (
        <Stack direction={'row'} justifyContent="center" spacing={1}>
            {memoizedRow.map((key) => (
                <ButtonBase
                    sx={button}
                    className={`btn-${key.name.toLowerCase()}`}
                    key={key.value}
                    disabled={setDisabledButton(key?.value, key?.type)}
                    onClick={() => handleKeyPress(key?.value)}
                >
                    {key.name === 'BACKSPACE' ? <BackSpaceIcon /> : key.label}
                </ButtonBase>
            ))}
        </Stack>
    );
};

export default RowButtons;
