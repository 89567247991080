import Button from '@mui/material/Button';

const CustomButton = ({ children, sx, ...props }) => {
    return (
        <Button
            sx={{
                textTransform: 'none',
                ...(sx && { ...sx }),
            }}
            {...props}
        >
            {children}
        </Button>
    );
};

export default CustomButton;
