import { FormHelperText } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentInputField } from '../../../store/slices/keyboardSlice';

const defaultSxStyle = {
    fontWeight: 600,
    fontSize: '1.2rem',
    paddingLeft: '.8rem',
    backgroundColor: '#e9e9e9',
    borderRadius: '5px',
};

const CustomInput = ({ sx = {}, name, error, errorMessage, ...props }) => {
    const { currentInputField } = useSelector(({ keyboard }) => keyboard);
    const dispatch = useDispatch();
    const handleFocus = ({ target }) => {
        dispatch(setCurrentInputField(target.name));
    };

    return (
        <>
            <InputBase
                sx={{
                    ...defaultSxStyle,
                    ...(currentInputField === name && {
                        border: error ? 'solid 3px red' : 'solid 3px #a04343',
                    }),
                    ...sx,
                }}
                {...props}
                name={name}
                id={name}
                onFocus={handleFocus}
            />
            {errorMessage && (
                <FormHelperText
                    sx={{ fontSize: '1rem', textAlign: 'center' }}
                    error={error}
                    id={`error-message-${name}`}
                >
                    {errorMessage}
                </FormHelperText>
            )}
        </>
    );
};

export default CustomInput;
