import {
    CardActionArea,
    CardContent,
    CardMedia,
    Typography,
} from '@mui/material';

const ProductCardButtonContent = ({ onHandleClick, product }) => {
    return (
        <CardActionArea onClick={() => onHandleClick(product)}>
            <CardMedia
                component="img"
                height="100"
                src={`/resources/assets/${product?.image}`}
                alt={product?.name}
            />
            {/* <PromoIndicator /> */}
            <CardContent sx={{ height: 93 }}>
                <Typography
                    variant="subtitle1"
                    gutterBottom
                    color="text.secondary"
                    sx={{ lineHeight: 1.5 }}
                >
                    {product?.name}
                </Typography>
            </CardContent>
        </CardActionArea>
    );
};

export default ProductCardButtonContent;
