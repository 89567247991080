import { createSlice } from '@reduxjs/toolkit';

export const keyboardSlice = createSlice({
    name: 'keyboard',
    initialState: {
        currentInputField: '',
        currentValue: '',
        enabledButtonByType: [],
        enabledButtonByValue: [],
        buttonsToBeDisabled: [],
    },
    reducers: {
        setCurrentInputField: (state, { payload }) => {
            const stateCopy = { ...state };
            stateCopy.currentInputField = payload;
            return stateCopy;
        },
        setCurrentValue: (state, { payload }) => {
            const stateCopy = { ...state };
            stateCopy.currentValue = payload;
            return stateCopy;
        },
        setEnabledButtons: (
            state,
            {
                payload: {
                    enabledButtonByType,
                    enabledButtonByValue,
                    buttonsToBeDisabled,
                },
            },
        ) => {
            state.enabledButtonByType = enabledButtonByType;
            state.enabledButtonByValue = enabledButtonByValue;
            state.buttonsToBeDisabled = buttonsToBeDisabled;
            return state;
        },
    },
});

export const { setCurrentInputField, setCurrentValue, setEnabledButtons } =
    keyboardSlice.actions;
export default keyboardSlice.reducer;
