import { createSlice } from '@reduxjs/toolkit';

export const configReducer = createSlice({
    name: 'config',
    initialState: {},
    reducers: {
        setConfigValue: (state, { payload }) => {
            return { ...state, ...payload };
        },
    },
});

// Action creators are generated for each case reducer function
export const { setConfigValue } = configReducer.actions;

export default configReducer.reducer;
