import { Backdrop, CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';
const CircularProgressbar = ({ loading, ...props }) => {
    const [isOpen, setOpen] = useState(false);
    useEffect(() => {
        setOpen(loading ? true : false);
    }, [loading]);
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isOpen}
            onClick={() => setOpen(false)}
        >
            <CircularProgress color="secondary" size={80} {...props} />
        </Backdrop>
    );
};

export default CircularProgressbar;
