export const beautifyPrice = (price, spacing = false) => {
    const numPrice = +price;
    if (!numPrice) return 0.0;
    if (spacing) return `$ ${numPrice.toFixed(2)}`;
    return `$${numPrice.toFixed(2)}`;
};

export function imageIsExistOnFolder(url) {
    const xmlHttpReq = new XMLHttpRequest();
    xmlHttpReq.open('GET', url, false);
    xmlHttpReq.send(null);
    return xmlHttpReq.status === 200;
}

export const getNestedValue = (obj, keys) =>
    keys.reduce(
        (xs, x) => (xs && xs[x] !== null && xs[x] !== undefined ? xs[x] : null),
        obj,
    );

export const getMainMenuNestedValue = (data) => {
    const keys = ['getProductMenuResponse', 'menuItems', 'menuItem'];
    return getNestedValue(data, keys);
};

export function getUniqueFromArray(arr, field) {
    // store the comparison  values in array
    const unique = arr
        .map((e) => e[field])

        // store the indexes of the unique objects
        .map((e, i, final) => final.indexOf(e) === i && i)

        // eliminate the false indexes & return unique objects
        .filter((e) => arr[e])
        .map((e) => arr[e]);

    return unique;
}
