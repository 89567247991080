import {createSlice} from '@reduxjs/toolkit';

export const settingsReducer = createSlice({
    name: 'settings',
    initialState: {
        showBanner: false,
        isLogin: false,
    },
    reducers: {
        toogleShowBanner: (state) => {
            state.showBanner = !state.showBanner;
            return state;
        },
    },
});

export const {toogleShowBanner} = settingsReducer.actions;
export default settingsReducer.reducer;
