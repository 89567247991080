import { ThemeProvider } from '@emotion/react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigationType } from 'react-router-dom';
import PageRoutes from './pageRoutes';
import { setPathUrlHistory } from './store/slices/uiSlice';
import useTheming from './theming';

function App() {
    const dispatch = useDispatch();
    const { storeName } = useSelector(({ ui }) => ui);
    const { themeOptions } = useTheming(storeName);
    const { pathname, search } = useLocation();
    const navigationType = useNavigationType();

    useEffect(() => {
        const history = { pathname, search };
        dispatch(setPathUrlHistory({ action: navigationType, history }));
    }, [pathname, search]);

    return (
        <ThemeProvider theme={themeOptions}>
            <PageRoutes />
        </ThemeProvider>
    );
    // return isPortraitView ? <PageRoutes /> : <AppLandScapeView />;
}

export default App;
