import { useEffect, useState } from 'react';
import { Paper, Grid, Container } from '@mui/material';

import { Outlet, useLocation } from 'react-router-dom';

import PageRoutes from './pageRoutes';
import Banner from './shared/components/banner';
import OrderSummary from './shared/components/order-summary';
import MenuTab from './shared/components/menu-tab';

const PaperContainer = ({ children, style }) => {
    return (
        <Paper
            sx={{
                padding: '1rem',
                minHeight: 'calc(100vh - 24px)',
                ...style,
            }}
            variant="outlined"
            square
        >
            {children}
        </Paper>
    );
};

function AppLandScapeView() {
    const { pathname } = useLocation();
    const [gridCount, setGridCount] = useState(8);
    const fullScreenPage = ['/product-fields', '/order-complete'];
    useEffect(() => {
        const gridCountValue = fullScreenPage.includes(pathname) ? 12 : 8;
        setGridCount(gridCountValue);
    }, [pathname]);
    return (
        <div className="main-container">
            {/* <Banner /> */}
            <div className="main-wrapper">
                <Grid container spacing={3}>
                    <Grid item xs={gridCount}>
                        <PaperContainer style={{ height: '100%' }}>
                            {/* <PageRoutes /> */}
                            {/* <MenuTab /> */}
                            <Outlet />
                        </PaperContainer>
                    </Grid>
                    {!fullScreenPage.includes(pathname) && (
                        <Grid item xs={4}>
                            <PaperContainer
                                style={{ height: 'calc(100vh - 24px)' }}
                            >
                                <OrderSummary />
                            </PaperContainer>
                        </Grid>
                    )}
                </Grid>
            </div>
        </div>
    );
}

export default AppLandScapeView;
