const { Helpers } = require("./Helpers");
const { RestHost } = require("./RestHost");
const { Logger } = require("./Logger");
const { Timer } = require("./Timer");

const PWSRest = {};
const retainCaseFields = ["registrationFields", "billPaymentFields", "settings", "promoCode", "fieldValues"];

PWSRest.active = false;

const sendGetRequest = (restUrl, successCallback, errorCallback) => {
    const requestTime = new Date();
    PWSRest.start();

    PWSRest.host.addHeader("x-bit-app-id", PWSRest.getApplicationId());
    PWSRest.host.removeHeader("X-JSON-ERROR-RESPONSE-FORMAT");
    PWSRest.host.get(restUrl,
        (response) => {
            PWSRest.end();

            const responseTime = new Date();
            Logger.log(`REST (Get) Done in ${responseTime.getTime() - requestTime.getTime()}`);

            if (response.headers) {
                PWSRest.setSessionIdHeader(response.headers["session-id"]);
            }
            if (typeof successCallback === "function") {
                successCallback(response.response ? response.response : response);
            }
        },
        (error) => {
            PWSRest.end();

            Logger.log(`REST (Failed): ${JSON.stringify(error)}`);
            if (typeof errorCallback === "function") {
                errorCallback(error);
            }
        },
    );
};

const sendPostRequest = (restUrl, data, successCallback, errorCallback) => {
    const requestTime = new Date();
    PWSRest.start();

    PWSRest.host.addHeader("x-bit-app-id", PWSRest.getApplicationId());
    PWSRest.host.removeHeader("X-JSON-ERROR-RESPONSE-FORMAT");
    PWSRest.host.post(restUrl, PWSRest.objectToUpperCase(data),
        (response) => {
            PWSRest.end();

            const responseTime = new Date();
            Logger.log(`REST (Get) Done in ${responseTime.getTime() - requestTime.getTime()}`);

            if (typeof successCallback === "function") {
                successCallback(response.response ? response.response : response);
            }
        },
        (error) => {
            PWSRest.end();

            Logger.log(`REST (Failed): ${JSON.stringify(error)}`);
            if (typeof errorCallback === "function") {
                errorCallback(error);
            }
        },
    );
};

const sendRequest = (sendType, restUrl, data, urlParams, successCallback, errorCallback, responseType, processOnBackground) => {
    const requestTime = new Date();
    const strParams = (urlParams != null) ? urlParams.toString() : "";

    PWSRest.host.addHeader("x-bit-app-id", PWSRest.getApplicationId());
    PWSRest.host.removeHeader("X-JSON-ERROR-RESPONSE-FORMAT");
    const customer = PWSRest.getAuthHeader();
    if (customer) {
        PWSRest.host.addHeader("Authorization", customer);
    }

    const sessionId = PWSRest.getSessionIdHeader();
    if (sessionId) {
        PWSRest.host.addHeader("session-id", sessionId);
    }

    PWSRest.start();
    PWSRest.host.send(sendType, restUrl + strParams, PWSRest.objectToUpperCase(data),
        (response) => {
            PWSRest.end();

            const responseTime = new Date();
            Logger.log(`REST (Get) Done in ${responseTime.getTime() - requestTime.getTime()}`);

            if (typeof successCallback === "function") {
                successCallback(response.response ? response.response : response);
            }
        },
        (error) => {
            PWSRest.end();

            Logger.log(`REST (Failed): ${JSON.stringify(error)}`);
            if (typeof errorCallback === "function") {
                errorCallback(error);
            }
        }, responseType, processOnBackground);
};


PWSRest.isActive = () => PWSRest.active === true;

PWSRest.start = () => PWSRest.start === true;

PWSRest.end = () => PWSRest.active === false;

PWSRest.getAuthHeader = () => localStorage.auth;

PWSRest.getSessionIdHeader = () => localStorage.sessionIdHeader;

PWSRest.setAuthHeader = (authHeader) => {
    localStorage.auth = authHeader;
};

PWSRest.setSessionIdHeader = (sessionId) => {
    localStorage.sessionIdHeader = sessionId;
};

PWSRest.generateAuthHeader = (username, password) => `Basic ${window.btoa(`${String(username).toUpperCase()}:${String(password).toUpperCase()}`)}`;

PWSRest.init = (callback, processOnBackground) => {
    const getWebServiceUrlCallback = (url) => {
        PWSRest.host = new RestHost(url);
        PWSRest.host.setIncludeResponseHeaders(true);

        if (callback) {
            callback();
        }
    };
    PWSRest.getLocalSetting("publicWebServiceUrl", getWebServiceUrlCallback, processOnBackground);
};

PWSRest.login = (rrn, password, successCallback, errorCallback) => {
    const url = Helpers.addForceDisableCacheHeader("login");
    const authHeader = PWSRest.generateAuthHeader(rrn, password);

    PWSRest.host.addHeader("Authorization", authHeader);
    PWSRest.get(
    url,
    (response) => {
        Timer.startCustomerIdTimerExpiration();
        PWSRest.setAuthHeader(authHeader);
        successCallback(response);
    },
    errorCallback,
  );
};

PWSRest.get = (restUrl, successCallback, errorCallback, isXml) => {
    if (PWSRest.host == null) {
        PWSRest.init(() => {
            sendGetRequest(restUrl, successCallback, errorCallback, isXml);
        });
    } else {
        sendGetRequest(restUrl, successCallback, errorCallback, isXml);
    }
};

PWSRest.post = (restUrl, data, successCallback, errorCallback) => {
    if (PWSRest.host == null) {
        PWSRest.init(() => {
            sendPostRequest(restUrl, data, successCallback, errorCallback);
        });
    } else {
        sendPostRequest(restUrl, data, successCallback, errorCallback);
    }
};

PWSRest.send = (sendType, restUrl, data, urlParams, successCallback, errorCallback, responseType, processOnBackground) => {
    if (PWSRest.host == null) {
        PWSRest.init(() => {
            sendRequest(sendType, restUrl, data, urlParams, successCallback, errorCallback, responseType, processOnBackground);
        });
    } else {
        sendRequest(sendType, restUrl, data, urlParams, successCallback, errorCallback, responseType, processOnBackground);
    }
};

PWSRest.objectToUpperCase = (arg) => {
    const objectCopy = Helpers.isArray(arg) ? arg.slice(0) : Object.assign({}, arg);
    Object.keys(objectCopy).forEach((key) => {
        if (!retainCaseFields.includes(key)) {
            const value = objectCopy[key];
            if (value === null || typeof value === "undefined") {
                delete objectCopy[key];
            } else if (typeof value === "string") {
                let upperCase = value.toUpperCase();
                upperCase = upperCase.trim();
                objectCopy[key] = PWSRest.replaceSpecialCharacters(upperCase);
            } else if (value && value.constructor && value.constructor.name === "Date") {
                objectCopy[key] = value;
            } else if (typeof value === "object") {
                objectCopy[key] = PWSRest.objectToUpperCase(value);
            }
        }
    });
    return objectCopy;
};

PWSRest.replaceSpecialCharacters = (arg) => {
    return arg.replace(/[`‘’]/g, "'");
};

PWSRest.getApplicationId = () => PWSRest.getLocalSetting("applicationId");

PWSRest.getLocalSetting = (key, callback, processOnBackground) => {
    if (PWSRest.localSettings == null || PWSRest.localSettings == undefined) {
        const restHost = new RestHost("resources/config/");
        restHost.get("settings.json",
            (response) => {
                if (callback) {
                    callback(response[key]);
                }
                PWSRest.localSettings = response;
            },
            (error) => Logger.error(error),
            RestHost.JSON,
            processOnBackground,
        );
    } else if (callback) {
        callback(PWSRest.localSettings[key]);
    } else {
        return PWSRest.localSettings[key];
    }
};

PWSRest.init();

export { PWSRest };
