import { Container, Grid } from '@mui/material';
import SidebarMenu from '../../shared/components/sidebar-menu';
import { Outlet } from 'react-router-dom';
import NavigationButtons from '../../shared/components/NavigationButton';
import Cart from '../../shared/components/cart';
import DeliCart from '../../shared/components/johnny-deli-cart';
import { useSelector } from 'react-redux';
import SevenElevenCart from '../../shared/components/7-eleven-cart';

const PortrainMainMenu = () => {
    const { storeName } = useSelector(({ ui }) => ui);
    return (
        <>
            <Container sx={{ paddingTop: '4rem' }}>
                <Grid container spacing={3}>
                    <Grid item xs={2} sm={3}>
                        <SidebarMenu />
                    </Grid>
                    <Grid item xs={10} sm={9}>
                        <Outlet />
                    </Grid>
                </Grid>
            </Container>
            {/* <NavigationButtons />                    */}
            {/* <Cart /> */}
            {storeName === '7-eleven' ? <SevenElevenCart /> : <DeliCart />}
            {/* {storeName === '7-eleven' ? <Cart /> : <DeliCart />} */}
        </>
    );
};

export default PortrainMainMenu;
