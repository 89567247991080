import { getUniqueFromArray } from '../../../shared/utils/helpers';

export const productFieldValidation = (productFields, currentFieldName) => {
    const error = { name: currentFieldName, errorMessage: '', hasError: true };
    const _productFields = getUniqueFromArray(productFields, 'name');
    const currentField = _productFields.find(
        (field) => field.name === currentFieldName,
    );
    const value = currentField?.value ? currentField.value.trim() : '';

    if (!value && currentField.required) {
        error.errorMessage = `${currentField.description} is required.`;
        return error;
    }

    if (value.length < currentField.fieldMin) {
        error.errorMessage = `Minimum value is ${currentField.fieldMin}`;
        return error;
    }

    if (value.length > currentField.fieldMax) {
        error.errorMessage = `Maximum value is ${currentField.fieldMax}`;
        return error;
    }

    error.hasError = false;
    return error;
};
