import { motion } from 'framer-motion';
import { Box, Button, styled, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import ExpandButtonIcon from '@mui/icons-material/ExpandCircleDown';

const boxWrapper = {
    display: 'flex',
    alignItems: 'center',
};

const ButtonStyle = {
    position: 'absolute',
    top: '-45px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '150px',
    color: '#000',
};
const img = {
    marginRight: '12px',
    height: '20px',
    width: '20px',
};

const label = {
    fontWeight: 700,
};

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(grey[500]),
    backgroundColor: grey[300],
    '&:hover': {
        backgroundColor: grey[400],
    },
}));
const ExpandButton = ({ expand = false, onClick, sx, disabled }) => {
    return (
        <ColorButton
            variant="outlined"
            onClick={onClick}
            sx={{ ...ButtonStyle, ...sx }}
            disabled={disabled}
        >
            <Box sx={boxWrapper}>
                {/* <Box
                    component={'img'}
                    style={img}
                    src={`resources/assets/images/${
                        expand ? 'colapse' : 'expand'
                    }-optimized.png`}
                    alt="Colapse"
                /> */}
                <Box
                    component={motion.div}
                    sx={{ marginRight: '10px', display: 'flex' }}
                    animate={{ rotate: expand ? 0 : 180 }}
                    transition={{ duration: 1 }}
                >
                    <ExpandButtonIcon />
                </Box>
                <Typography
                    sx={{ ...label, ...(disabled && { color: '#fff' }) }}
                    variant="subtitle1"
                >
                    {expand ? 'COLAPSE' : 'EXPAND'}
                </Typography>
            </Box>
        </ColorButton>
    );
};

export default ExpandButton;
