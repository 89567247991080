import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AboutPage from './pages/about';
import MainMenuPage from './pages/main-menu';
import ProductFields from './pages/product-fields';
import ProductsPage from './pages/product-page';
import PortrainMainMenu from './pages/portrait-main-menu';
import useScreenOrientation from './shared/hooks/useScreenOrientationHook';
import OrderCompletePage from './pages/order-complete';
import AppLandScapeView from './AppLandscapeView';

function NotFound() {
    return <div className="page-not-found">Page not found</div>;
}

const LazyRoute = ({ children }) => (
    <React.Suspense fallback={<>...</>}>{children}</React.Suspense>
);

const MainMenuView = () => {
    const isPortraitView = useScreenOrientation();

    // return isPortraitView ? <PortrainMainMenu /> : <MainMenuPage />;
    return isPortraitView ? <PortrainMainMenu /> : <AppLandScapeView />;
};

const PageRoutes = () => {
    return (
        <Routes>
            <Route
                path="/"
                element={
                    <LazyRoute>
                        <MainMenuView />
                    </LazyRoute>
                }
            >
                <Route index element={<ProductsPage />} />
            </Route>
            <Route path="products" element={<ProductsPage />} />
            <Route path="about" element={<AboutPage />} />
            <Route path="product-fields" element={<ProductFields />} />
            <Route path="order-complete" element={<OrderCompletePage />} />
            <Route path="*" element={NotFound()} />
        </Routes>
    );
};

export default PageRoutes;
