export const cartContainer = {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#fff',
    padding: '0.8rem',
    paddingTop: '80px',
};

export const iconButtonStyle = {
    position: 'relative',
    bottom: '38px',
    transform: 'translateX(calc(50% - 56px))',
    left: '50%',
};

export const dataContainer = {
    marginBottom: '2rem',
};

export const actionButtons = {
    position: 'fixed',
    bottom: '1rem',
    left: 0,
    right: 0,
    margin: '0 2rem',
};

export const backdropStyle = {
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: ' #fff',
    zIndex: 1201,
};
