import { useDispatch } from 'react-redux';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import './deli-order.style.scss';
import {
    addQuantity,
    removeItem,
    subtractQuantity,
} from '../../../../../store/slices/OrderItemSlice';
import { beautifyPrice } from '../../../../utils/helpers';
import CustomButton from '../../../CustomButton';
import { markAsAddedToCart } from '../../../../../store/slices/mainMenuSlice';
import { useState } from 'react';
import { Box, Button, Dialog, IconButton } from '@mui/material';
import ConfirmationDialogBody from '../interventions/confirmationDialogBody';
import { removeProductField } from '../../../../../store/slices/productFieldsSlice';
import { markProductAsOrdered } from '../../../../../store/slices/productPageSlice';
import { useEffect } from 'react';

const QuantityButton = ({ productId, quantity }) => {
    const dispatch = useDispatch();
    const handleAddQuantity = () => dispatch(addQuantity(productId));
    const handleSubtractQuantity = () => dispatch(subtractQuantity(productId));
    return (
        <Stack spacing={2} direction="row" className="quantity-wrapper">
            <CustomButton
                onClick={handleSubtractQuantity}
                variant="outlined"
                color="primary"
                sx={{ width: 40, height: 40, minWidth: 40, borderWidth: 4 }}
                disabled={quantity === 0}
                size="small"
            >
                <RemoveIcon />
            </CustomButton>

            <CustomButton
                onClick={handleAddQuantity}
                variant="outlined"
                color="primary"
                sx={{ width: 40, height: 40, minWidth: 40, borderWidth: 4 }}
                size="small"
            >
                <AddIcon />
            </CustomButton>
        </Stack>
    );
};

const JdOrderItem = ({
    image,
    name: title,
    itemPrice,
    productId,
    quantity,
    menuId,
}) => {
    const dispatch = useDispatch();
    const [showIntervention, setShowIntervention] = useState(false);
    useEffect(() => {
        if (quantity <= 0) handleRemoveItem();
    }, [quantity]);
    const handleRemoveItem = () => {
        dispatch(removeItem(menuId));
        dispatch(removeProductField(menuId));
        dispatch(markProductAsOrdered({ menuId, isOrdered: false }));
        setShowIntervention(false);
    };

    const showConfirmation = () => setShowIntervention(true);

    return (
        <>
            <ul className="deli-order-itemlist">
                <li>
                    <CustomButton
                        onClick={showConfirmation}
                        size="small"
                        sx={{ width: 32, height: 32, minWidth: 32 }}
                        color="primary"
                        variant="contained"
                    >
                        <CloseIcon />
                    </CustomButton>
                </li>
                <li className="quantity">
                    {' '}
                    <Typography
                        sx={{
                            fontWeight: 700,
                            border: 'solid',
                            borderRadius: '8px',
                        }}
                        color="primary"
                        variant="h6"
                    >
                        {quantity}
                    </Typography>
                </li>
                <li className="title">
                    <Typography
                        sx={{ fontWeight: 700 }}
                        color="primary"
                        variant="h6"
                        noWrap
                    >
                        {title}
                    </Typography>
                </li>
                <li className="quantity-btn">
                    <QuantityButton productId={productId} quantity={quantity} />
                </li>
                <li className="price">
                    {' '}
                    <Typography
                        sx={{ fontWeight: 700 }}
                        color="primary"
                        variant="h6"
                    >
                        {beautifyPrice(itemPrice, true)}
                    </Typography>
                </li>
            </ul>
            <Dialog
                sx={{
                    color: '#fff',
                }}
                open={showIntervention}
            >
                <ConfirmationDialogBody
                    onSubmit={handleRemoveItem}
                    confirmationMessage={`Are you sure you want to remove ${title} from your order?`}
                    onCloseDialog={() => setShowIntervention(false)}
                />
            </Dialog>
        </>
    );
};

export default JdOrderItem;
