const { Helpers } = require("./Helpers");

const MessageArgs = {};

MessageArgs.replaceVars = (message, args, language) => {
    let result = message;
    if (args) {
        const argsArray = Helpers.isArray(args) ? args : [args];
        if (argsArray && argsArray.length) {
            argsArray.forEach((arg) => {
                const search = `${arg.key}`;
                const regExp = new RegExp(Helpers.escapeRegExp(search), "g");
                const value = language ? arg[`value_${language}`] || arg.value : arg.value;
                result = result.replace(regExp, value);
            });
        }
    }
    return result;
};

export { MessageArgs };
