const { RestHost } = require("./RestHost");
const { Logger } = require("./Logger");
const { Helpers } = require("./Helpers");

const Rest = {};
const retainCaseFields = ["registrationFields", "billPaymentFields", "settings", "actions", "sendUsageDataRequest", "fieldValues"];

Rest.active = false;

Rest.isActive = () => Rest.active === true;

Rest.start = () => {
    Rest.active = true;
};

Rest.end = () => {
    Rest.active = false;
};

Rest.init = (callback, processOnBackground) => {
    const getWebServiceUrlCallback = (url) => {
        Rest.host = new RestHost(url);

        if (callback) {
            callback();
        }
    };
    Rest.getLocalSetting("kioskWebServiceUrl", getWebServiceUrlCallback, processOnBackground);
};

const sendPostRequest = (restUrl, data, successCallback, errorCallback, isXml) => {
    const requestTime = new Date();
    Rest.start();

    Rest.host.post(restUrl, Rest.objectToUpperCase(data),
        (response) => {
            Rest.end();
            const responseTime = new Date();
            Logger.log(`REST SUCCESS in ${responseTime.getTime() - requestTime.getTime()} ms - POST / (${restUrl})`);
            Logger.performance(restUrl, requestTime.getTime(), responseTime.getTime());
            if (typeof successCallback === "function") {
                successCallback(response);
            }
        },
        (error) => {
            Rest.end();
            const responseTime = new Date();
            Logger.log(`REST FAILED in ${responseTime.getTime() - requestTime.getTime()} ms - POST / (${restUrl})`);
            Logger.performance(restUrl, requestTime.getTime(), responseTime.getTime(), error);
            if (typeof errorCallback === "function") {
                if (typeof error === "string") {
                    errorCallback(error);
                } else {
                    const webServiceFault = error.webServiceFault;
                    if (webServiceFault && webServiceFault.moneygramException) {
                        errorCallback(webServiceFault.moneygramException.errorString, webServiceFault);
                    } else {
                        errorCallback(webServiceFault.errorMessage, webServiceFault);
                    }
                }
            }
        }, isXml,
    );
};

Rest.post = (restUrl, data, successCallback, errorCallback, isXml) => {
    if (Rest.host == null) {
        Rest.init(() => {
            sendPostRequest(restUrl, data, successCallback, errorCallback, isXml);
        });
    } else {
        sendPostRequest(restUrl, data, successCallback, errorCallback, isXml);
    }
};

const sendRequest = (sendType, restUrl, data, urlParams, successCallback,
  errorCallback, responseType, processOnBackground) => {
    const requestTime = new Date();
    const strParams = (urlParams != null) ? urlParams.toString() : "";

    Rest.start();
    Rest.host.send(sendType, restUrl + strParams, Rest.objectToUpperCase(data),
        (response) => {
            Rest.end();

            const responseTime = new Date();
            Logger.log(`REST SUCCESS in ${responseTime.getTime() - requestTime.getTime()} ms - ${sendType} / (${restUrl + strParams})`);
            Logger.performance(restUrl, requestTime.getTime(), responseTime.getTime());
            if (typeof successCallback === "function") {
                successCallback(response);
            }
        },
        (error) => {
            Rest.end();
            const responseTime = new Date();
            Logger.log(`REST FAILED in ${responseTime.getTime() - requestTime.getTime()} ms - ${sendType} / (${restUrl + strParams})`);
            Logger.performance(restUrl, requestTime.getTime(), responseTime.getTime(), error);
            if (typeof errorCallback === "function") {
                if (typeof error === "string") {
                    errorCallback(error);
                } else {
                    const webServiceFault = error.webServiceFault;
                    if (webServiceFault && webServiceFault.moneygramException) {
                        errorCallback(webServiceFault.moneygramException.errorString, webServiceFault);
                    } else {
                        errorCallback(webServiceFault.errorMessage, webServiceFault);
                    }
                }
            }
        },
        responseType,
        processOnBackground);
};

Rest.send = (sendType, restUrl, data, urlParams, successCallback,
    errorCallback, responseType, processOnBackground) => {
    const sendRequestCallback = () => {
        sendRequest(sendType, restUrl, data, urlParams, successCallback,
      errorCallback, responseType, processOnBackground);
    };

    if (Rest.host == null) {
        Rest.init(sendRequestCallback, processOnBackground);
    } else {
        sendRequestCallback();
    }
};

Rest.riaSend = (sendType, restUrl, data, urlParams, successCallback,
    errorCallback, responseType, processOnBackground) => {
    retainCaseFields.push("deliveryMethod", "amountType", "requiredFields", "relation", "sourceOfFunds");

    const sendRequestCallback = () => {
        sendRequest(sendType, restUrl, data, urlParams, successCallback,
      errorCallback, responseType, processOnBackground);
    };

    if (Rest.host == null) {
        Rest.init(sendRequestCallback, processOnBackground);
    } else {
        sendRequestCallback();
    }
};

Rest.mtkxSend = (sendType, restUrl, data, urlParams, successCallback,
    errorCallback, responseType, processOnBackground) => {

    retainCaseFields.push("deliveryMethod", "amountType", "requiredFields", "relation", "sourceOfFunds", "fieldValues");

    const sendRequestCallback = () => {
        sendRequest(sendType, restUrl, data, urlParams, successCallback,
      errorCallback, responseType, processOnBackground);
    };

    if (Rest.host == null) {
        Rest.init(sendRequestCallback, processOnBackground);
    } else {
        sendRequestCallback();
    }
};

Rest.login = (rrn, password, successCallback, errorCallback) => {
    const requestData = {
        getCustomerIdRequest: {
            customerCredentials: {
                registrationRetrievalNumber: rrn,
                password,
            },
        },
    };

    Rest.post("customers/getCustomerId", requestData, (response) => {
        let customerId = null;
        if (response && response.getCustomerIdResponse
        && response.getCustomerIdResponse.customerIdStr) {
            customerId = response.getCustomerIdResponse.customerIdStr.replace("L", "");
        }
        successCallback(customerId);
    }, errorCallback);
};

Rest.objectToUpperCase = (arg) => {
    const objectCopy = Helpers.isArray(arg) ? arg.slice(0) : Object.assign({}, arg);
    Object.keys(objectCopy).forEach((key) => {
        if (!retainCaseFields.includes(key)) {
            const value = objectCopy[key];
            if (typeof value === "string") {
                let upperCase = value.toUpperCase();
                upperCase = upperCase.trim();
                objectCopy[key] = Rest.replaceSpecialCharacters(upperCase);
            } else if (value && value.constructor && value.constructor.name === "Date") {
                objectCopy[key] = value;
            } else if (typeof value === "object") {
                objectCopy[key] = Rest.objectToUpperCase(value);
            }
        }
    });
    return objectCopy;
};

Rest.replaceSpecialCharacters = (arg) => {
    return arg.replace(/[`‘’]/g, "'");
};

Rest.getApplicationId = () => Rest.localSettings.applicationId;

Rest.getLocalSetting = (key, callback, processOnBackground) => {
    if (Rest.localSettings == null) {
        const restHost = new RestHost("resources/config/");
        restHost.get("settings.json",
            (response) => {
                if (callback) {
                    callback(response[key]);
                }
                Rest.localSettings = response;
            },
            (error) => Logger.error(error),
            RestHost.JSON,
            processOnBackground,
        );
    } else {
        callback(Rest.localSettings[key]);
    }
};

Rest.init();

export { Rest };
