const SoundManager = {};
const allowClassNames = ["card-wrapper", "biller-box-wrapper", "review-item-action", "selection-trigger",
    "flag-container", "collapsible", "help-item", "close-button", "placeholder", "with-sound"];


const hasClass = (target, className) => {
    let element = target;
    do {
        if (element.classList && element.classList.contains(className)) {
            return true;
        }
        element = element.parentNode;
    } while (element);
    return false;
};

const allowedSound = (target) => {
    if (!target || target.disabled) {
        return false;
    }

    let allowed = false;

    if (target instanceof Element) {
        const className = target.className;
        const tagName = target.tagName;
        if (tagName === "BUTTON" && hasNoSoundClass(className)) {
            allowed = true;
        } else if (tagName === "INPUT" && hasNoSoundClass(className)) {
            allowed = true;
        } else if (tagName === "SELECT" && hasNoSoundClass(className)) {
            allowed = true;
        } else if (tagName === "TEXTAREA" && hasNoSoundClass(className)) {
            allowed = true;
        } else if (tagName === "A" && hasNoSoundClass(className)) {
            allowed = true;
        } else if (tagName === "SPAN" && target.parentElement && target.parentElement.nodeName === "A") {
            allowed = true;
        } else if (tagName === "SPAN" && className === "review-item-action edit") {
            allowed = true;
        } else if (tagName === "SPAN" && className === "review-action remove") {
            allowed = true;
        } else {
            for (let x = 0; x < allowClassNames.length; x++) {
                if (hasClass(target, allowClassNames[x]) && !hasClass(target, "disabled")) {
                    allowed = true;
                    break;
                }
            }
        }
    }

    return allowed;
};

const hasNoSoundClass = (className) => {
    return className.indexOf("disabled") === -1
        && className.indexOf("no-sound") === -1;
};

const isIE = function () {
    const isIE10Below = /MSIE/i.test(navigator.userAgent);
    const isIE11 = /rv:11.0/i.test(navigator.userAgent);
    return isIE10Below || isIE11;
};

Object.defineProperty(HTMLMediaElement.prototype, "playing", {
    get: function(){
        return !!(this.currentTime > 0 && !this.paused && !this.ended && this.readyState > 2);
    }
})


SoundManager.tapSound = () => {
    const sound = document.getElementById("keyTone");
    if (sound && sound.play && !sound.playing) {
        const playPromise = sound.play();
        if (playPromise !== null && !isIE()) {
            playPromise.catch(() => {})
        }
    }
};

window.onclick = (event) => {
    if (event && event.target) {
        const target = event.target;
        if (allowedSound(target)) {
            SoundManager.tapSound();
        }
    }
};

export default SoundManager;
