const { Helpers } = require("./Helpers");

const Logger = {};

Logger.ACTIVITY = "Activity.txt";
Logger.PERFORMANCE = "Performance.txt";
Logger.ERROR_LOG = "ErrorLog.txt";
Logger.PRINT_PARAMETERS = "printparameters";

Logger.MONTH_NAMES = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

Logger.log = (message) => {
    if (typeof message === "object") {
        message = JSON.stringify(message);
    }

    console.log(`ACTIVITY: ${message}`);
    let logs = getLocalStorage(Logger.ACTIVITY) || "";
    logs = `${logs}\r\n${Logger.getCurrentDateTime()}    ${message}`;
    setLocalStorage(Logger.ACTIVITY, logs);
};

Logger.performance = (url, start, end, error) => {
    const message = `${url}, ${(end - start) / 1000}${error ? " with error" : ""}`;

    let logs = getLocalStorage(Logger.PERFORMANCE) || "";
    logs = `${logs}\r\n${Logger.getCurrentDateTime()}    ${message}`;
    setLocalStorage(Logger.PERFORMANCE, logs);

    if (error) {
        Logger.error(`ERROR IN REST\r\n    URL: ${url}\r\n    MESSAGE: ${error}`);
    } else {
        console.log(`PERFORMANCE: ${message}`);
    }
};

Logger.error = (message) => {
    console.log('error mesage log.......................', message);
    console.error(message);
    let logs = getLocalStorage(Logger.ERROR_LOG) || "";
    logs = `${logs}\r\n${Logger.getCurrentDateTime()}    ${message}`;
    setLocalStorage(Logger.ERROR_LOG, logs);
};

Logger.getCurrentDateTime = () => {
    const currentDate = new Date();
    const dateTime = `${currentDate.getDate()} ${Logger.MONTH_NAMES[currentDate.getMonth()]} ${currentDate.getFullYear()
             } ${Helpers.padZero(currentDate.getHours())}:${Helpers.padZero(currentDate.getMinutes())
             }:${Helpers.padZero(currentDate.getSeconds())}`;
    return dateTime;
};

Logger.printUrl = (urlString, urlParams) => {
    const url = `${urlString}&longprint=true`;
    clearLocalStorage(Logger.PRINT_PARAMETERS);
    setLocalStorage(Logger.PRINT_PARAMETERS, urlParams);
    window.open(url, "_self");
};

window.getLocalStorage = function (key) {
    return getStorage().getItem(key);
};

window.clearLocalStorage = function (key) {
    getStorage().removeItem(key);
};

window.setLocalStorage = function (key, value) {
    getStorage().setItem(key, value);
};

const getStorage = () => localStorage || {
    getItem: () => undefined,
    setItem: () => {},
    removeItem: () => {},
};

export { Logger };
