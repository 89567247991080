import { IconButton, Stack } from '@mui/material';
import {
    KeyboardDoubleArrowUp,
    KeyboardDoubleArrowDown,
} from '@mui/icons-material';
const PaginationArrow = ({
    onArrowDown,
    onArrowUp,
    disabledArrowUp,
    disabledArrowDown,
}) => {
    return (
        <Stack direction="row" spacing={2} justifyContent="center">
            <IconButton
                onClick={onArrowUp}
                color="primary"
                size="large"
                disabled={disabledArrowUp}
            >
                <KeyboardDoubleArrowUp sx={{ fontSize: '4rem' }} />
            </IconButton>
            <IconButton
                onClick={onArrowDown}
                color="primary"
                size="large"
                disabled={disabledArrowDown}
            >
                <KeyboardDoubleArrowDown sx={{ fontSize: '4rem' }} />
            </IconButton>
        </Stack>
    );
};

export default PaginationArrow;
