import { useState, useEffect, useRef } from 'react';
import {
    Stack,
    Box,
    Button,
    IconButton,
    Slide,
    Typography,
} from '@mui/material';
import {
    KeyboardDoubleArrowUp,
    KeyboardDoubleArrowDown,
} from '@mui/icons-material';
import {
    menuContainer,
    arrowButton,
    container,
    menuItemImage,
    sideMenuTitle,
} from './style';
import './sidebar-menu.scss';
import useNavigateWithQueryString from '../../hooks/useNavigateWithDefaultQueryString';
import { useDispatch, useSelector } from 'react-redux';
import useSidebarDummyData from '../../hooks/useSidebarDummyData';

import { setStoreName } from '../../../store/slices/uiSlice';
const SidebarMenu = () => {
    const { storeName } = useSelector(({ ui }) => ui);
    const dispatch = useDispatch();
    const navigate = useNavigateWithQueryString();
    const data = useSidebarDummyData();
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize] = useState(4);
    const [isFadeIn] = useState(true);
    const [direction, setDirection] = useState('down');
    const [lastPage, setLastPage] = useState(0);
    const containerRef = useRef(null);

    const logoName =
        storeName === '7-eleven'
            ? '7eleven-logo-square.png'
            : 'johnny-deli-logo.PNG';
    const logo = `resources/assets/images/common/${logoName}`;
    useEffect(() => {
        setLastPage(Math.ceil(data.length / pageSize) - 1);
        if (data.length <= pageSize) setCurrentPage(0);
    }, [data.length]);

    const handleArrowUpClick = () => {
        setCurrentPage((prevState) => prevState - 1);
        setDirection('up');
    };
    const handleArrowDownClick = () => {
        setCurrentPage((prevState) => prevState + 1);
        setDirection('down');
    };

    return (
        <Box sx={container} className="sidebar-menu">
            <img
                onClick={() =>
                    dispatch(
                        setStoreName(
                            storeName !== '7-eleven' ? '7-eleven' : 'deli',
                        ),
                    )
                }
                className="logo"
                src={logo}
            />
            <IconButton
                onClick={handleArrowUpClick}
                color="primary"
                size="large"
                disabled={currentPage === 0}
            >
                <KeyboardDoubleArrowUp sx={arrowButton} />
            </IconButton>
            <Stack
                sx={{ minHeight: 700 }}
                direction={'column'}
                alignItems="center"
                spacing={2}
                ref={containerRef}
            >
                {data
                    .slice(
                        currentPage * pageSize,
                        currentPage * pageSize + pageSize,
                    )
                    .map(({ image, name, menuId }, i) => (
                        <Slide
                            in={isFadeIn}
                            timeout={500}
                            key={name + i}
                            direction={direction}
                            container={containerRef.current}
                        >
                            <Box
                                // component="button"

                                onClick={() =>
                                    navigate(`?menuId=${menuId}&title=${name}`)
                                }
                                sx={{ ...menuContainer(storeName) }}
                                // color="secondary"
                            >
                                <Box
                                    component="img"
                                    sx={menuItemImage(storeName)}
                                    src={`resources/assets/images/sidebar-menu/${image}`}
                                />
                                {/* {storeName !== '7-eleven' && ( */}
                                <Typography
                                    sx={sideMenuTitle}
                                    variant="subtitle2"
                                >
                                    {name}
                                </Typography>
                                {/* )} */}
                            </Box>
                        </Slide>
                    ))}
            </Stack>
            <IconButton
                onClick={handleArrowDownClick}
                color="secondary"
                disabled={lastPage === currentPage}
                size="large"
            >
                <KeyboardDoubleArrowDown sx={arrowButton} />
            </IconButton>
        </Box>
    );
};

export default SidebarMenu;
