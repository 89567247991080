const { RestHost } = require("./RestHost");
const { Rest } = require("./Rest");
const { Helpers } = require("./Helpers");

const Analytics = {};

Analytics.initialize = function(data, callback) {
    Analytics.data = data;
    Analytics.generateCID();
    Analytics.ipaddress = Helpers.getURLParameter("ipaddress") || "127.0.0.1";
    if (callback) {
        callback();
    }
}

Analytics.generateCID = function () {
    const time = (new Date()).getTime();
    Analytics.cid = time;
};

Analytics.formulateFields = function(name) {
    const fields = [];
    const data = Analytics.data[name];

    if (data != null && data.length > 0) {
        data.map((item) => { fields.push(Analytics.getItem(name, item)); });
    }
    return fields;
}

Analytics.getItem = function(name, item) {
    const key = item.key;
    const value = item.value;

    if (value == "#set") {
        return { "name": key, "value": name };
    } else if (value.startsWith("#")) {
        return { "name": key, "value": Analytics[value.slice(1)] };
    }
    return { "name": key, "value": value };
}

Analytics.sendUsageData = function (usageName) {
    if (Analytics.data) {
        Analytics.sendAnalytics(usageName);
    } else {
        Analytics.initialize(() => {Analytics.sendAnalytics(usageName)});
    }
}

Analytics.sendAnalytics = function (usageName) {
    const common = Analytics.formulateFields("Common");
    const fields = common.concat(Analytics.formulateFields(usageName));
    const request = {
        "sendUsageDataRequest": {
            "fieldList": [...fields]

        }
    };

    Rest.send(RestHost.POST, "devices/sendUsageData", request, null,
        null, null, RestHost.JSON, true);
};

export { Analytics };
