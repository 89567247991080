import { combineReducers } from '@reduxjs/toolkit';
import configReducer from './slices/configSlice';
import keyboardSlice from './slices/keyboardSlice';
import mainMenuSlice from './slices/mainMenuSlice';
import OrderItemSlice from './slices/OrderItemSlice';
import productFieldsSlice from './slices/productFieldsSlice';
import productPageSlice from './slices/productPageSlice';
import settingsReducer from './slices/settingsSlice';
import uiReducer from './slices/uiSlice';

export default combineReducers({
    keyboard: keyboardSlice,
    mainMenu: mainMenuSlice,
    orderItems: OrderItemSlice,
    productFieldPage: productFieldsSlice,
    productPage: productPageSlice,
    settings: settingsReducer,
    ui: uiReducer,
    config: configReducer,
});
