import { Grid, Skeleton, Stack } from '@mui/material';
import UseScreenOrientation from '../hooks/useScreenOrientationHook';
const SkeletonProgress = () => {
    const isPortraitView = UseScreenOrientation();
    const arr = [...Array(9).keys()];
    return (
        <>
            <Stack
                sx={{
                    width: '100%',
                    marginBottom: '1.5rem',
                    marginTop: '1rem',
                }}
                direction={'row'}
                justifyContent={isPortraitView ? 'center' : 'flex-start'}
            >
                <Skeleton
                    variant="rectangular"
                    width={'20%'}
                    height={40}
                    component="h4"
                />
            </Stack>

            <Grid container spacing={3}>
                {arr.map((data, i) => (
                    <Grid item md={4} sm={4} xs={6} key={'skeleton' + i}>
                        <Skeleton
                            variant="rectangular"
                            width={'100%'}
                            height={193}
                        />
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

export default SkeletonProgress;
