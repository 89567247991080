import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { Grid, Stack, Box, Typography, Grow } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import useApiHook from '../../shared/hooks/useApiHook';
import { getProductMenuRequest } from '../../shared/services/restApi';
import { getMainMenuNestedValue } from '../../shared/utils/helpers';
import ProductCard from './components/ProductCard';
import { setProducts } from '../../store/slices/productPageSlice';
import { addOrderItem } from '../../store/slices/OrderItemSlice';
import { addProductFields } from '../../store/slices/productFieldsSlice';
import useScreenOrientation from '../../shared/hooks/useScreenOrientationHook';
import SkeletonProgress from '../../shared/components/SkeletonProgress';
import CustomSnackbar from '../../shared/components/CustomSnackbar';
import useNavigateWithQueryString from '../../shared/hooks/useNavigateWithDefaultQueryString';
import PaginationArrow from '../../shared/components/PaginationArrow';
import CustomPagination from '../../shared/components/CustomPagination';

const ProductsPage = () => {
    const { products: productDetails } = useSelector(
        ({ productPage }) => productPage,
    );

    const { storeName } = useSelector(({ ui }) => ui);

    const { items } = useSelector(({ orderItems }) => orderItems);
    const dispatch = useDispatch();
    const navigate = useNavigateWithQueryString();
    const { search } = useLocation();

    const isPortraitView = useScreenOrientation();
    const {
        request: getProductsMenuByMenuId,
        data: products,
        loading,
        error,
    } = useApiHook(getProductMenuRequest, getMainMenuNestedValue);

    const [title, setTitle] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [lastPage, setLastPage] = useState(0);
    const [pageSize] = useState(isPortraitView ? 9 : 8);
    const [fadein, setFadein] = useState(true);
    const containerRef = useRef(null);

    useEffect(() => {
        dispatch(setProducts([]));
        const { menuId, title } = queryString.parse(search, {
            parseNumbers: true,
        });
        if (menuId) {
            setTitle(title);
        }
        getProductsMenuByMenuId(menuId || null);
    }, [search]);

    useEffect(() => {
        if (products) {
            dispatch(setProducts(products));
            // setLastPage(Math.ceil(products.length / pageSize) - 1);
            const pageCount = Math.ceil(products.length / pageSize);
            const _lastPage = isPortraitView ? pageCount - 1 : pageCount;
            setLastPage(_lastPage);
            if (products.length <= pageSize) setCurrentPage(0);
        }
    }, [products]);

    useEffect(() => {
        if (!fadein) {
            setFadein(true);
        }
    }, [currentPage]);

    const handleClick = (product) => {
        const queryParam = `?menuId=${product?.menuId}&title=${product?.name}`;
        // const path = isPortraitView ? queryParam : '/products' + queryParam;
        const path = queryParam;
        if (product?.productId) {
            dispatch(addOrderItem(product));
            const item = items.find((item) => item?.menuId === product?.menuId);
            if (!item) dispatch(addProductFields(product));
            return;
        }

        navigate(path);
    };

    const handleArrowUpClick = () => {
        setFadein(false);
        setTimeout(() => {
            setCurrentPage((prevState) => prevState - 1);
        }, 100);
    };
    const handleArrowDownClick = () => {
        setFadein(false);
        setTimeout(() => {
            setCurrentPage((prevState) => prevState + 1);
        }, 100);
    };

    if (loading) return <SkeletonProgress />;

    return (
        <Box
            sx={{
                padding: '1rem',
                position: 'relative',
                height: '100%',
            }}
        >
            <Stack
                sx={{ position: 'relative', width: '100%' }}
                direction={'row'}
                justifyContent={isPortraitView ? 'center' : 'flex-start'}
            >
                <Typography
                    color={storeName !== '7-eleven' ? 'primary' : null}
                    variant="h4"
                >
                    {title}
                </Typography>
            </Stack>

            <Box
                sx={{
                    minHeight: '80%',
                    position: 'relative',
                    marginTop: '2rem',
                }}
            >
                <Grid container spacing={3} ref={containerRef}>
                    {/* {[...productDetails, ...dummyData] */}
                    {productDetails
                        .slice(
                            currentPage * pageSize,
                            currentPage * pageSize + pageSize,
                        )
                        .map((data, i) => (
                            <Grid
                                item
                                lg={3}
                                md={4}
                                xs={4}
                                key={data?.name + i}
                            >
                                <AnimatePresence>
                                    {fadein && (
                                        <Box
                                            component={motion.div}
                                            sx={{ height: '100%' }}
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            transition={{
                                                ease: 'easeOut',
                                                duration: 1,
                                            }}
                                        >
                                            <ProductCard
                                                product={data}
                                                onHandleClick={handleClick}
                                            />
                                        </Box>
                                    )}
                                </AnimatePresence>
                            </Grid>
                        ))}
                </Grid>

                {productDetails.length <= 0 && (
                    <Typography mt="4rem" align="center">
                        {' '}
                        No products found.
                    </Typography>
                )}
            </Box>

            {isPortraitView && (
                <PaginationArrow
                    onArrowDown={handleArrowDownClick}
                    onArrowUp={handleArrowUpClick}
                    disabledArrowUp={currentPage === 0}
                    disabledArrowDown={currentPage === lastPage}
                />
            )}

            {!isPortraitView && productDetails.length > pageSize && (
                <CustomPagination
                    count={lastPage}
                    setCurrentPage={setCurrentPage}
                />
            )}
            <CustomSnackbar
                open={error.error}
                message={error.errorMessage}
                severity="error"
                position={
                    isPortraitView
                        ? { vertical: 'top', horizontal: 'center' }
                        : { vertical: 'bottom', horizontal: 'left' }
                }
            />
        </Box>
    );
};

export default ProductsPage;
