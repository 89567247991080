import { useEffect } from 'react';

import { Box, Stack } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import RowButtons from './components/RowButtons';

import { wrapper, container, keyboardSize } from './style';
import keyboard from './keyboard-key.json';

import './virtual-keyboard.scss';

import validations from './validation.json';
import {
    setCurrentValue,
    setEnabledButtons,
} from '../../../store/slices/keyboardSlice';
import useScreenOrientation from '../../hooks/useScreenOrientationHook';

const VirtualKeyboard = ({
    onChange,
    useValidationJSON = false,
    customStyle,
    onEnter,
    ...props
}) => {
    const { currentInputField, currentValue: value } = useSelector(
        ({ keyboard }) => keyboard,
    );
    const dispatch = useDispatch();
    const isPortraitView = useScreenOrientation();

    useEffect(() => {
        if (currentInputField) {
            if (useValidationJSON) {
                handleSetEnabledButton();
            }
        }
    }, [currentInputField]);

    useEffect(() => {
        dispatch(setCurrentValue(value));
    }, [value]);

    // useEffect(() => {
    //     dispatch(setCurrentValue(inputValue));
    // const event = { target: { name: currentInputField, value } };
    // onChange(key, event);
    // }, [inputValue, key]);

    const handleKeyPress = (key) => {
        if (key === 'ENTER') return onEnter();
        onChange(key);
    };

    const handleSetEnabledButton = () => {
        if (currentInputField) {
            const buttonTypes = ['FUNCTIONAL_BUTTON'];
            const disabledButtons = [];
            let enabledButtonByValue = [];
            const selectedValidation = validations.find(
                ({ fieldName }) => fieldName === currentInputField,
            );
            if (selectedValidation?.allowedNumbers === true) {
                buttonTypes.push('NUMERIC');
            }

            if (
                selectedValidation?.allowedSpecialCharacters &&
                selectedValidation?.allowedSpecialCharacters !== null
            ) {
                enabledButtonByValue = Array.from(
                    selectedValidation?.allowedSpecialCharacters,
                );
            }

            if (
                selectedValidation?.type === 'NUMERIC' &&
                selectedValidation?.allowedNumbers === true
            ) {
                disabledButtons.push('SPACE');
            }

            buttonTypes.push(selectedValidation?.type);

            dispatch(
                setEnabledButtons({
                    enabledButtonByType: buttonTypes,
                    enabledButtonByValue,
                    buttonsToBeDisabled: disabledButtons,
                }),
            );
        }
    };

    return (
        <Box
            sx={{
                ...container,
                ...keyboardSize(isPortraitView),
                ...(customStyle && customStyle),
            }}
        >
            <Stack sx={wrapper} direction={'column'} spacing={1}>
                {Object.keys(keyboard).map((row) => (
                    <RowButtons
                        row={keyboard[row]}
                        key={row}
                        name={row}
                        handleKeyPress={handleKeyPress}
                        {...props}
                    />
                ))}
            </Stack>
        </Box>
    );
};

export default VirtualKeyboard;
