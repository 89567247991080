import { createSlice } from '@reduxjs/toolkit';
import { imageIsExistOnFolder } from '../../shared/utils/helpers';
// const initialPayload = Array(20).fill({
//     menuId: '002',
//     name: 'Dummy Product',
//     displayOrder: 10,
//     active: true,
//     productId: 370,
//     image: 'images/dummy.jpg',
// });

const initialPayload = [
    {
        menuId: 403,
        name: 'Dummy Product one',
        displayOrder: 10,
        active: true,
        productId: 370,
        image: 'images/dummy.jpg',
    },
    {
        menuId: 404,
        name: 'Dummy Product two',
        displayOrder: 10,
        active: true,
        productId: 370,
        image: 'images/dummy.jpg',
    },
    {
        menuId: 405,
        name: 'Dummy Product 3',
        displayOrder: 10,
        active: true,
        productId: 370,
        image: 'images/dummy.jpg',
    },
    {
        menuId: 6,
        name: 'Dummy Product 4',
        displayOrder: 10,
        active: true,
        productId: 370,
        image: 'images/dummy.jpg',
    },
    {
        menuId: 12,
        name: 'Dummy Product 4',
        displayOrder: 10,
        active: true,
        productId: 370,
        image: 'images/dummy.jpg',
    },
    {
        menuId: 503,
        name: 'Dummy Product 5',
        displayOrder: 10,
        active: true,
        productId: 370,
        image: 'images/dummy.jpg',
    },
    {
        menuId: 111,
        name: 'Dummy Product 6',
        displayOrder: 10,
        active: true,
        productId: 370,
        image: 'images/dummy.jpg',
    },
    {
        menuId: 222,
        name: 'Dummy Product 22',
        displayOrder: 10,
        active: true,
        productId: 370,
        image: 'images/dummy.jpg',
    },
    {
        menuId: 124,
        name: 'Dummy Product 123',
        displayOrder: 10,
        active: true,
        productId: 370,
        image: 'images/dummy.jpg',
    },
    {
        menuId: 1244,
        name: 'Dummy Product dfsdf',
        displayOrder: 10,
        active: true,
        productId: 370,
        image: 'images/dummy.jpg',
    },

    {
        menuId: 234,
        name: 'Dummy Product dsf',
        displayOrder: 10,
        active: true,
        productId: 370,
        image: 'images/dummy.jpg',
    },

    {
        menuId: 55,
        name: 'Dummy Product odfgne',
        displayOrder: 10,
        active: true,
        productId: 370,
        image: 'images/dummy.jpg',
    },
    {
        menuId: 403,
        name: 'Dummy Product one',
        displayOrder: 10,
        active: true,
        productId: 370,
        image: 'images/dummy.jpg',
    },
];

export const productPageReducer = createSlice({
    name: 'productPage',
    initialState: {
        products: [],
    },
    reducers: {
        setProducts: (state, { payload }) => {
            const defaultImage = 'images/place-holder.png';
            const publicFolderPath = 'resources/assets/';
            const payloadCopy = Array.isArray(payload)
                ? [...payload]
                : [payload];
            payloadCopy.forEach((product, index, arr) => {
                if (!product?.image) {
                    arr[index]['image'] = defaultImage;
                }
                if (
                    product.image &&
                    !imageIsExistOnFolder(publicFolderPath + product.image)
                ) {
                    arr[index]['image'] = defaultImage;
                }
            });
            state.products = payloadCopy;
            return state;
        },
        markProductAsOrdered: (state, { payload: { menuId, isOrdered } }) => {
            const updatedProducts = state.products.map((product) => {
                if (product?.menuId === menuId && product?.productId) {
                    product.isOrdered = isOrdered;
                    return product;
                }
                return product;
            });
            state.products = updatedProducts;
            return state;
        },
    },
});

export const { setProducts, markProductAsOrdered } = productPageReducer.actions;
export default productPageReducer.reducer;
