import { useLocation, useNavigate } from 'react-router-dom';
import queryStr from 'query-string';
const useNavigateWithQueryString = () => {
    const { search } = useLocation();
    const navigate = useNavigate();

    const addDefaultQueryString = (path = '') => {
        const searchParam = queryStr.parse(search);

        if (searchParam?.agentId && searchParam?.agentSequence) {
            const index = path.indexOf('?');
            const defaultSearchParam = 'agentId=43810613&agentSequence=1';
            if (index < 0) {
                return `${path}?${defaultSearchParam}`;
            }
            return (
                path.slice(0, index + 1) +
                defaultSearchParam +
                '&' +
                path.slice(index + 1)
            );
        }
        return path;
    };
    const navigateFucn = (path = '', secondParam) => {
        const newpath = addDefaultQueryString(path);
        if (secondParam) {
            return navigate(newpath, secondParam);
        }
        navigate(newpath);
    };
    return navigateFucn;
};

export default useNavigateWithQueryString;
