import { Stack, Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import CustomButton from '../CustomButton';
import OrderItem from './components/order-item';
import OrderItemDialogList from './components/OrderItemDialogList';
import PriceSummary from './components/price-summary';
import './order-summary.style.scss';

const OrderSummary = ({ itemSize = 3 }) => {
    const { items, totalAmount } = useSelector(({ orderItems }) => orderItems);

    const [open, setOpen] = useState(false);
    return (
        <div className="order-summary-container">
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        objectFit: 'contain',
                        height: '100px',
                        position: 'relative',
                        bottom: '8px',
                    }}
                    component={'img'}
                    src="resources/assets/images/common/johnny-deli-logo.PNG"
                ></Box>
                <Typography
                    align="center"
                    gutterBottom
                    variant="body1"
                    className="title"
                >
                    Your Order
                </Typography>
            </Box>

            <Stack direction="column" spacing={1}>
                {items.length > 0 &&
                    items
                        .slice(0, itemSize)
                        .map((item) => (
                            <OrderItem {...item} key={item?.productId} />
                        ))}
                {!items.length && (
                    <Typography mt={'1rem'} variant="subtitle1" align="center">
                        Cart is empty.
                    </Typography>
                )}
                {items.length > itemSize && (
                    <CustomButton
                        size="large"
                        onClick={() => setOpen(true)}
                        sx={{ marginTop: '1rem !important' }}
                    >
                        View More
                    </CustomButton>
                )}
            </Stack>
            <PriceSummary />
            <OrderItemDialogList
                onClose={() => setOpen(false)}
                open={open}
                items={items}
                totalAmount={totalAmount}
            />
        </div>
    );
};

export default OrderSummary;
