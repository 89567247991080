import { create } from 'apisauce';

const baseUrl = window.location.origin;
const apiClient = create({
    baseURL: baseUrl,
});

export const getConfiguratinSettings = () => {
    return apiClient.get('/resources/config/settings.json');
};
