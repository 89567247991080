import { Box, Container, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import CustomButton from '../../shared/components/CustomButton';
import useNavigateWithQueryString from '../../shared/hooks/useNavigateWithDefaultQueryString';
import UseScreenOrientation from '../../shared/hooks/useScreenOrientationHook';
import { setStoreName } from '../../store/slices/uiSlice';

const containerStyle = {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '90vh',
};

const OrderCompletePage = () => {
    const { storeName } = useSelector(({ ui }) => ui);
    const { state } = useLocation();
    const navigate = useNavigateWithQueryString();

    const dispatch = useDispatch();
    const isPortraitView = UseScreenOrientation();
    const logoName =
        storeName === '7-eleven'
            ? '7eleven-logo-square.png'
            : 'johnny-deli-logo.PNG';
    const logo = `resources/assets/images/common/${logoName}`;
    useEffect(() => {
        dispatch({ type: 'CLEAR_STORE' });
        dispatch(setStoreName(storeName));
    }, []);

    const handleOrderAgain = () => {
        // dispatch(setStoreName('7-eleven'));
        navigate('/');
    };
    return (
        <Container sx={containerStyle}>
            <Box
                component="img"
                sx={{
                    ...(isPortraitView && {
                        width: storeName === '7-eleven' ? '30%' : '60%',
                    }),
                    ...(!isPortraitView && {
                        width: storeName === '7-eleven' ? '17%' : '30%',
                    }),
                    marginBottom: '4rem',
                    objectFit: 'contain',
                    height: '192px',
                }}
                src={logo}
                alt={
                    storeName === '7-eleven' ? '7-eleven logo' : "Johnny's Deli"
                }
            />
            <Typography mb="2rem" variant="h1" color={'primary'}>
                Thank you!
            </Typography>
            <Typography mb="2.5rem" variant="subtitle1">
                Please take the voucher and pay at the counter.
            </Typography>

            <Box
                component="img"
                sx={{ marginBottom: '1rem', height: '100px' }}
                src={`https://barcode.tec-it.com/barcode.ashx?data=${
                    state?.code + state?.voucher
                }&code=Code128&translate-esc=true&eclevel=L`}
            />
            <CustomButton
                onClick={handleOrderAgain}
                size="large"
                color="primary"
                variant="contained"
            >
                Order again
            </CustomButton>
        </Container>
    );
};

export default OrderCompletePage;
