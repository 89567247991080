import queryString from 'query-string';
import { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

const useAgentDetails = () => {
    const { search } = useLocation();
    const [agentDetails, setAgentDetails] = useState({
        agentId: '',
        agentSequence: null,
    });
    useEffect(() => {
        if (search) {
            const searchParam = queryString.parse(search);
            if (searchParam?.agentId && searchParam?.agentSequence) {
                const { agentId, agentSequence } = searchParam;
                setAgentDetails({ agentId, agentSequence });
            }
        }
    }, []);
    return agentDetails;
};

export default useAgentDetails;
