import apiClient from './client';
export const getProductMenuRequest = (menuId) => {
    const payload = {
        getProductMenuRequest: {
            agentDetails: {
                agentId: '43810613',
                agentSequence: '1',
            },
            ...(menuId && { menuId }),
        },
    };
    return apiClient.post('payments/getProductMenu', payload);
};

export const prepareVoucherRequest = (payload) =>
    apiClient.post('payments/prepareProductVoucher', payload);
