const button = {
    outline: 'none',
    borderRadius: '5px',
    border: 'none',
    color: 'white',
    width: '2rem',
    height: '2rem',
    backgroundColor: '#686868',
    textAlign: 'center',
    fontFamily: 'Roboto, sans-serif',
    fontSize: 21,
    boxShadow: '1 4 2 0 rgba(0, 0, 0, 0.1)',
    '&:active': {
        backgroundColor: '#9CA2AC',
    },
    '&:disabled': {
        color: '#575554',
        backgroundColor: '#343331',
        boxShadow: '1px 4px 2px 0 rgba(0, 0, 0, 0.1)',
    },
};

const wrapper = {
    backgroundColor: '#25211f',
    padding: '1rem',
};

const buttonDisabled = {
    color: '#575554',
    backgroundColor: '#343331',
    boxShadow: '1px 4px 2px 0 rgba(0, 0, 0, 0.1)',
};

const container = {
    position: 'fixed',
    bottom: '1rem',
    width: '100vw',
    right: 0,
};

const keyboardSize = (isPortrait) => {
    const portraitSize = {
        transform: 'scale(1.2)',
        bottom: '1rem',
    }
    const landscapeSize = {
        transform: 'scale(1.5)',
        marginBottom: '3rem'
    }

    return isPortrait ? portraitSize : landscapeSize;
}

export { button, buttonDisabled, wrapper, container, keyboardSize };
