import { createSlice } from '@reduxjs/toolkit';

export const mainMenuReducer = createSlice({
    name: 'mainMenu',
    initialState: {
        mainMenuItems: [],
    },
    reducers: {
        setMainMenuItems: (state, { payload }) => {
            state.mainMenuItems = payload;
            return state;
        },
        markAsAddedToCart: (state, { payload: { menuId, isAddedToCart } }) => {
            const mainMenuItemsCopy = state.mainMenuItems.map((menuItem) => {
                if (menuItem?.menuId === menuId) {
                    console.log('is this caled.');
                    menuItem.isAddedToCart = isAddedToCart;
                    return menuItem;
                }
                return menuItem;
            });
            state.mainMenuItems = mainMenuItemsCopy;
            return state;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setMainMenuItems, markAsAddedToCart } = mainMenuReducer.actions;

export default mainMenuReducer.reducer;
