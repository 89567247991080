import { Grid, Box, Fab, Stack, Typography } from '@mui/material';

import { motion } from 'framer-motion';

import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import OrderItem from '../order-summary/components/order-item';
import { useEffect, useRef, useState } from 'react';
import {
    actionButtons,
    cartContainer,
    dataContainer,
    iconButtonStyle,
    backdropStyle,
} from './cart-style';
import CustomButton from '../CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { getTotalAmount } from '../../../store/slices/OrderItemSlice';
import CartHeader from './components/CartHeader';
import useNavigateWithQueryString from '../../hooks/useNavigateWithDefaultQueryString';
import ExpandButton from '../ExpandButton';
import SevenElevenOrderItem from './components/order-item';

const SevenElevenCart = () => {
    const { items, counter, totalAmount } = useSelector(
        ({ orderItems }) => orderItems,
    );

    const dispatch = useDispatch();
    const navigate = useNavigateWithQueryString();
    const [expanded, setExpanded] = useState(false);
    const containerRef = useRef();
    useEffect(() => {
        dispatch(getTotalAmount());
    }, [counter]);

    return (
        <Box sx={expanded ? backdropStyle : {}}>
            <Box
                sx={cartContainer}
                component={motion.div}
                initial={{ height: !expanded ? 400 : '80vh' }}
                animate={{ height: expanded ? '80vh' : 400 }}
                transition={{ duration: 1 }}
                ref={containerRef}
            >
                {/* <Fab
                color="secondary"
                aria-label="expand"
                // size="large"
                sx={iconButtonStyle}
                onClick={() => setExpanded(!expanded)}
            >
                <Box
                    component={motion.div}
                    animate={{ rotate: !expanded ? 0 : 180 }}
                    transition={{ duration: 1 }}
                >
                    <ArrowUpIcon />
                </Box>
            </Fab> */}

                <CartHeader totalAmount={totalAmount} />
                <ExpandButton
                    expand={expanded}
                    onClick={() => setExpanded(!expanded)}
                    sx={{ top: '-24px' }}
                    disabled={!items.length ? true : false}
                />
                {items.length > 0 && (
                    <Grid sx={dataContainer} container spacing={2}>
                        {items
                            .slice(0, !expanded ? 4 : items.length)
                            .map((data, i) => (
                                <Grid item sm={6} key={data?.name + i}>
                                    <SevenElevenOrderItem {...data} />
                                </Grid>
                            ))}
                    </Grid>
                )}
                {!items.length && (
                    <Typography align="center" variant="h5" gutterBottom>
                        Your cart is empty
                    </Typography>
                )}
                {items.length > 0 && (
                    <Stack
                        sx={{ marginTop: '1rem', ...actionButtons }}
                        direction="row"
                        spacing={2}
                        justifyContent="center"
                    >
                        <CustomButton
                            size="large"
                            fullWidth
                            color="secondary"
                            variant="contained"
                        >
                            Cancel Order
                        </CustomButton>
                        <CustomButton
                            size="large"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={totalAmount <= 0}
                            onClick={() => navigate('/product-fields')}
                        >
                            Complete Order
                        </CustomButton>
                    </Stack>
                )}
            </Box>
        </Box>
    );
};

export default SevenElevenCart;
