import { prepareVoucherRequest } from '../../shared/services/restApi';
import { getUniqueFromArray } from '../../shared/utils/helpers';

export const prepareVoucher = (agentDetails, reduxStore) => {
    const { productCode } = reduxStore.config?.defaultValues;
    const { items } = reduxStore.orderItems;
    const { productFields } = reduxStore.productFieldPage;
    const product = items.map((product) => ({
        productId: product?.productId,
        quantity: product?.quantity,
        productPrice: product?.itemPrice,
        total: product?.quantity * product?.itemPrice,
    }));
    const productField = getUniqueFromArray(productFields, 'name').map(
        (field) => ({
            entryMethod: field?.entryMethod,
            name: field?.name,
            value: field?.value,
        }),
    );
    const payload = {
        prepareProductVoucherRequest: {
            agentDetails,
            productCode,
            products: {
                product,
            },
            productFields: {
                productField,
            },
        },
    };
    return prepareVoucherRequest(payload);
};
