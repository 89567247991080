import { useDispatch } from 'react-redux';

import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

import './order.style.scss';
import {
    addQuantity,
    removeItem,
    subtractQuantity,
} from '../../../../../store/slices/OrderItemSlice';
import { beautifyPrice } from '../../../../utils/helpers';
import CustomButton from '../../../CustomButton';
import { markAsAddedToCart } from '../../../../../store/slices/mainMenuSlice';
import { useState } from 'react';
import { Dialog } from '@mui/material';
import ConfirmationDialogBody from '../interventions/confirmationDialogBody';
import { removeProductField } from '../../../../../store/slices/productFieldsSlice';
import { useEffect } from 'react';
import { markProductAsOrdered } from '../../../../../store/slices/productPageSlice';

const QuantityButton = ({ productId, quantity }) => {
    const dispatch = useDispatch();
    const handleAddQuantity = () => dispatch(addQuantity(productId));
    const handleSubtractQuantity = () => dispatch(subtractQuantity(productId));
    return (
        <Stack spacing={2} direction="row" className="quantity-wrapper">
            <CustomButton
                onClick={handleSubtractQuantity}
                variant="contained"
                disabled={quantity === 0}
                size="small"
            >
                <RemoveIcon />
            </CustomButton>
            <Typography sx={{ fontWeight: 700 }} variant="h5">
                {quantity}
            </Typography>
            <CustomButton
                onClick={handleAddQuantity}
                variant="contained"
                size="small"
            >
                <AddIcon />
            </CustomButton>
        </Stack>
    );
};

const OrderItem = ({
    image,
    name: title,
    itemPrice,
    productId,
    quantity,
    menuId,
}) => {
    const dispatch = useDispatch();
    const [showIntervention, setShowIntervention] = useState(false);
    useEffect(() => {
        if (quantity <= 0) handleRemoveItem();
    }, [quantity]);

    const handleRemoveItem = () => {
        dispatch(removeItem(menuId));
        dispatch(removeProductField(menuId));
        dispatch(markProductAsOrdered({ menuId, isOrdered: false }));
        setShowIntervention(false);
    };

    const showConfirmation = () => setShowIntervention(true);

    return (
        <Paper variant="outlined" className="order-item-container">
            <div className="title-wrapper">
                <div className="order-item">
                    <img
                        // src="resources\assets\images\place-holder.png",
                        src={`resources/assets/${image}`}
                        alt={title}
                        className="order-item-image"
                    />
                    <Typography sx={{ fontWeight: 700 }} variant="subtitle2">
                        {title}
                    </Typography>
                </div>
                <span className="spacer" />
                <Typography sx={{ fontWeight: 700 }} variant="subtitle2">
                    {beautifyPrice(itemPrice)}
                </Typography>
            </div>
            <Stack direction="row" justifyContent="space-between">
                <QuantityButton productId={productId} quantity={quantity} />
                <CustomButton
                    onClick={showConfirmation}
                    size="small"
                    color="secondary"
                >
                    Remove
                </CustomButton>
            </Stack>
            <Dialog
                sx={{
                    color: '#fff',
                }}
                open={showIntervention}
            >
                <ConfirmationDialogBody
                    onSubmit={handleRemoveItem}
                    confirmationMessage={`Are you sure you want to remove ${title} from your order?`}
                    onCloseDialog={() => setShowIntervention(false)}
                />
            </Dialog>
        </Paper>
    );
};

export default OrderItem;
