import { Snackbar, Alert } from '@mui/material';
import { useEffect, useState } from 'react';
const CustomSnackbar = ({
    message,
    open: openProp,
    severity = 'success',
    position,
}) => {
    const [open, setOpen] = useState(false);
    useEffect(() => {
        if (openProp) setOpen(true);
    }, [openProp]);
    return (
        <Snackbar
            open={open}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
                ...position,
            }}
            onClose={() => setOpen(false)}
            autoHideDuration={6000}
        >
            <Alert severity={severity}>{message}</Alert>
        </Snackbar>
    );
};

export default CustomSnackbar;
