import { useDispatch } from 'react-redux';

import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';
import AddIcon from '@mui/icons-material/AddCircleOutline';

import './7-eleven-order.style.scss';
import {
    addQuantity,
    removeItem,
    subtractQuantity,
} from '../../../../../store/slices/OrderItemSlice';
import { beautifyPrice } from '../../../../utils/helpers';
import { useState } from 'react';
import { Box, Dialog, IconButton } from '@mui/material';
import ConfirmationDialogBody from '../../../order-summary/components/interventions/confirmationDialogBody';
import { removeProductField } from '../../../../../store/slices/productFieldsSlice';

const QuantityButton = ({ productId, quantity }) => {
    const dispatch = useDispatch();
    const handleAddQuantity = () => dispatch(addQuantity(productId));
    const handleSubtractQuantity = () => dispatch(subtractQuantity(productId));
    return (
        <Stack spacing={1} direction="row" className="quantity-wrapper">
            <IconButton
                onClick={handleSubtractQuantity}
                color="secondary"
                disabled={quantity === 0}
            >
                <RemoveIcon />
            </IconButton>
            <CustomTypography>{quantity}</CustomTypography>
            <IconButton onClick={handleAddQuantity} color="secondary">
                <AddIcon />
            </IconButton>
        </Stack>
    );
};

const CustomTypography = ({ children }) => (
    <Typography sx={{ fontWeight: 700 }} variant="subtitle2">
        {children}
    </Typography>
);
const SevenElevenOrderItem = ({
    name: title,
    itemPrice,
    productId,
    quantity,
    menuId,
}) => {
    const dispatch = useDispatch();
    const [showIntervention, setShowIntervention] = useState(false);
    const handleRemoveItem = () => {
        dispatch(removeItem(menuId));
        dispatch(removeProductField(menuId));
        setShowIntervention(false);
    };

    const showConfirmation = () => setShowIntervention(true);

    return (
        <>
            <ul className="eleven-order-itemlist" style={{ color: '#000' }}>
                <li>
                    <QuantityButton productId={productId} quantity={quantity} />
                </li>
                <li>
                    <CustomTypography>{title}</CustomTypography>
                </li>
                <li>
                    <CustomTypography>
                        {beautifyPrice(itemPrice)}
                    </CustomTypography>
                </li>
            </ul>
        </>
    );
};

export default SevenElevenOrderItem;
