import { ButtonGroup, Fab, Stack } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
// import CustomButton from './CustomButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';

const NavigationButtons = ({ additionalXs }) => {
    const location = useLocation();
    const navigate = useNavigate();
    return (
        <Stack
            sx={{ ...additionalXs }}
            direction={'row'}
            justifyContent="flex-start"
            pl={'2rem'}
            pt={'2rem'}
        >
            {/* <ButtonGroup
                variant="contained"
                aria-label="outlined primary button group"
            >
                <CustomButton onClick={() => navigate(-1)} size="large">
                    Prev
                </CustomButton>
                <CustomButton onClick={() => navigate(1)} size="large">
                    Next
                </CustomButton>
            </ButtonGroup> */}
            <Fab
                size="small"
                color="primary"
                onClick={() => navigate(-1)}
                aria-label="add"
            >
                <ArrowBackIcon />
            </Fab>
            {/* <Fab size="small" color="secondary" aria-label="add">
                <ArrowForward />
            </Fab> */}
        </Stack>
    );
};

export default NavigationButtons;
