const menuContainer = (storeName) => {
    const is7eleven = storeName === '7-eleven';
    const deliStyles = {
        backgroundColor: '#CC3333',
        overflow: 'hidden',
        border: 'solid 2px #CC3333',
        color: 'white',
        position: 'relative',
    };
    return {
        border: 'solid 2px',
        borderRadius: '1.5rem',
        width: '9rem',
        height: '9rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        borderColor: is7eleven ? 'black' : 'none',
        ...(!is7eleven && deliStyles),
        '&:active': {
            transform: 'scale(1.15) !important',
        },
    };
};

const arrowButton = {
    fontSize: '2.5rem',
    color: '#9e9e9e',
};

const container = {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
};
const menuImg = {
    maxWidth: '100%',
    objectFit: 'contain',
    padding: '10px',
};
const deliMenuImg = {
    position: 'relative',
    right: '-3rem',
    bottom: '.6rem',
};
const menuItemImage = (storeName) => {
    const is7eleven = storeName === '7-eleven';
    return {
        ...menuImg,
        ...(!is7eleven && deliMenuImg),
    };
};

const sideMenuTitle = {
    position: 'absolute',
    bottom: '0.8rem',
    textAlign: 'start',
    left: '0.5rem',
    lineHeight: 1,
    color: '#fff',
};
export { menuContainer, arrowButton, container, menuItemImage, sideMenuTitle };
