import { createTheme } from '@mui/material';

const deliTheme = {
    // typography: {
    //     fontFamily: ['EB Garamond'],
    // },
    palette: {
        type: 'light',
        primary: {
            main: '#c62828',
        },
        secondary: {
            main: '#e65100',
        },
    },
};

const sevenElevenTheme = {
    palette: {
        primary: {
            main: '#008061',
        },
        secondary: {
            main: '#ffba0d',
        },
    },
};

const useTheming = (storeName = '7-eleven') => {
    const theme = storeName === '7-eleven' ? sevenElevenTheme : deliTheme;
    const themeOptions = createTheme({
        ...theme,
    });
    return { themeOptions };
};

export default useTheming;
