import {
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Box,
    DialogActions,
    Button,
    Stack,
    Typography,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useNavigateWithQueryString from '../../../hooks/useNavigateWithDefaultQueryString';
import { beautifyPrice } from '../../../utils/helpers';
import CustomButton from '../../CustomButton';
import OrderItem from './order-item';

const OrderItemDialogList = ({ items, open, onClose, totalAmount }) => {
    const navigate = useNavigateWithQueryString();
    return (
        <Dialog open={open}>
            <DialogTitle id="scroll-dialog-title">
                My Order{' '}
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent
                dividers
                sx={{
                    minWidth: 550,
                    maxWidth: 700,
                    minHeight: 'calc(100vh - 200px)',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Grid container spacing={2}>
                    {items.map((item) => (
                        <Grid key={item?.productId} item sm={6}>
                            <OrderItem {...item} />
                        </Grid>
                    ))}
                </Grid>
                <span className="spacer" />
                <Stack direction="row" justifyContent={'space-between'}>
                    <Typography variant="h6">Total</Typography>
                    <Typography variant="h6">
                        {beautifyPrice(totalAmount)}
                    </Typography>
                </Stack>
            </DialogContent>{' '}
            <DialogActions>
                <CustomButton
                    variant="contained"
                    onClick={() => navigate('/product-fields')}
                    color="primary"
                >
                    Complete Order
                </CustomButton>
            </DialogActions>
        </Dialog>
    );
};

export default OrderItemDialogList;
