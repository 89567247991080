import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import {
    clearCart,
    getTotalAmount,
} from '../../../../../store/slices/OrderItemSlice';
import { beautifyPrice } from '../../../../utils/helpers';

import './price-summary.style.scss';
import CustomButton from '../../../CustomButton';
import useNavigateWithQueryString from '../../../../hooks/useNavigateWithDefaultQueryString';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { useState } from 'react';

const PriceSummary = () => {
    const { totalAmount, counter, items } = useSelector(
        ({ orderItems }) => orderItems,
    );

    const dispatch = useDispatch();
    const navigate = useNavigateWithQueryString();

    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    useEffect(() => {
        dispatch(getTotalAmount());
    }, [counter]);

    const handleClearCart = () => {
        dispatch(clearCart());
        setShowConfirmationDialog(false);
    };

    return (
        <>
            <div className="price-summary-container">
                <div className="total-container">
                    <Typography variant="h6">Total</Typography>
                    <Typography variant="h6">
                        {beautifyPrice(totalAmount)}
                    </Typography>
                </div>
                <Stack spacing={1} direction="row">
                    <CustomButton
                        variant="outlined"
                        color="primary"
                        onClick={() => setShowConfirmationDialog(true)}
                        style={{ marginRight: 8, lineHeight: '2.5rem' }}
                        fullWidth
                        size="small"
                        disabled={totalAmount <= 0}
                    >
                        Cancel Order
                    </CustomButton>
                    <CustomButton
                        variant="contained"
                        color="primary"
                        size="small"
                        fullWidth
                        sx={{ lineHeight: '2.5rem' }}
                        disabled={totalAmount <= 0}
                        onClick={() => navigate('/product-fields')}
                    >
                        Complete Order
                    </CustomButton>
                </Stack>
            </div>
            <Dialog
                open={showConfirmationDialog}
                onClose={() => setShowConfirmationDialog(false)}
            >
                <DialogContent>
                    <Typography>
                        Are you sure you want to cancel your order?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <CustomButton
                        color="primary"
                        onClick={() => setShowConfirmationDialog(false)}
                    >
                        No
                    </CustomButton>
                    <CustomButton color="primary" onClick={handleClearCart}>
                        Yes
                    </CustomButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default PriceSummary;
