import { useEffect, useState } from 'react';
import CustomInput from '../../shared/components/custom-input';
import VirtualKeyboard from '../../shared/components/virtual-keyboard';
import { useDispatch, useSelector } from 'react-redux';
import {
    setErrorMessage,
    setFieldValue,
} from '../../store/slices/productFieldsSlice';
import { getUniqueFromArray } from '../../shared/utils/helpers';
import { Container, Typography } from '@mui/material';
import { setEnabledButtons } from '../../store/slices/keyboardSlice';
import { prepareVoucher } from './product-field.service';
import useAgentDetails from '../../shared/hooks/useAgentDetails';
import useApiHook from '../../shared/hooks/useApiHook';
import CircularProgressbar from '../../shared/components/CircularProgressbar';
import useNavigateWithQueryString from '../../shared/hooks/useNavigateWithDefaultQueryString';
import CustomSnackbar from '../../shared/components/CustomSnackbar';
import { productFieldValidation } from './utils/helper';
import NavigationButtons from '../../shared/components/NavigationButton';
import useKeyboardOnPress from '../../shared/hooks/useKeyBoardOnPress';
import UseScreenOrientation from '../../shared/hooks/useScreenOrientationHook';

const ProductFields = () => {
    const dispatch = useDispatch();
    const agentDetails = useAgentDetails();
    const navigate = useNavigateWithQueryString();
    const {
        defaultValues: { productCode },
    } = useSelector(({ config }) => config);
    const {
        loading,
        request: prepareVoucherRequest,
        data: response,
        error,
    } = useApiHook(prepareVoucher);
    const { productFields } = useSelector(
        ({ productFieldPage }) => productFieldPage,
    );

    const { currentInputField } = useSelector(({ keyboard }) => keyboard);
    const [inputCurrentValue, setInputCurrentValue] = useState('');

    const store = useSelector((reduxStore) => reduxStore);
    const isPortraitView = UseScreenOrientation();
    const [page, setPage] = useState(0);
    const [currentProductField, setCurrentProductField] = useState({
        dataType: null,
        value: '',
    });

    useEffect(() => {
        if (currentInputField)
            dispatch(
                setFieldValue({
                    name: currentInputField,
                    value: inputCurrentValue,
                }),
            );
    }, [currentProductField, inputCurrentValue]);

    useEffect(() => {
        const isPrepareVoucherSuccess =
            typeof response === 'object' &&
            Object.keys(response).includes('prepareProductVoucherResponse');
        if (isPrepareVoucherSuccess) {
            const voucher =
                response?.prepareProductVoucherResponse?.voucherReferenceNumber;
            navigate('/order-complete', {
                replace: true,
                state: { voucher, code: productCode },
            });
        }
    }, [response]);

    useEffect(() => {
        if (Array.isArray(productFields)) {
            const productField = getUniqueFromArray(productFields, 'name');
            if (productField.length) {
                setCurrentProductField(productField[page]);
            }
        }
    }, []);

    useEffect(() => {
        if (currentProductField?.dataType) {
            if (currentProductField?.dataType === 'ALPHANUMERIC') {
                // dispatch(setCurrentInputField(currentProductField?.name))
                const buttonTypes = ['FUNCTIONAL_BUTTON', 'TEXT', 'NUMERIC'];
                const enabledButtonByValue = Array.from('-.');
                dispatch(
                    setEnabledButtons({
                        enabledButtonByType: buttonTypes,
                        enabledButtonByValue,
                        buttonsToBeDisabled: [],
                    }),
                );
            }
        }
    }, [currentProductField?.dataType]);

    const handleOnEnter = () => {
        const { productFields } = store.productFieldPage;

        const error = productFieldValidation(productFields, currentInputField);

        if (error.errorMessage) {
            dispatch(setErrorMessage(error));
            return;
        }
        prepareVoucherRequest(agentDetails, store);
    };

    const handleChange = (key) => {
        if (productFields[page]?.hasError) {
            dispatch(
                setErrorMessage({
                    name: currentInputField,
                    errorMessage: '',
                    hasError: false,
                }),
            );
        }
        switch (key) {
            case 'DELETE':
                setInputCurrentValue('');
                break;
            case 'BACKSPACE':
                setInputCurrentValue((prevValue) =>
                    String(prevValue).slice(0, -1),
                );
                break;
            case 'SPACE': {
                setInputCurrentValue((prevValue) => `${prevValue}${' '}`);
                break;
            }
            case 'ENTER':
                handleOnEnter();
                break;
            default:
                setInputCurrentValue((prevValue) => prevValue + key);
                break;
        }
    };

    return (
        <>
            <NavigationButtons />
            <Container>
                <Typography
                    sx={{ fontWeight: 600, marginTop: '4rem' }}
                    mb={'1.5rem'}
                    color={'primary'}
                    align="center"
                    variant="h3"
                >
                    {currentProductField?.description}
                </Typography>
                <CustomInput
                    value={productFields[page]?.value}
                    fullWidth
                    name={productFields[page]?.name}
                    autoFocus
                    sx={{ fontSize: '1.5rem', height: '3rem' }}
                    error={productFields[page]?.hasError}
                    errorMessage={productFields[page]?.errorMessage}
                />
            </Container>
            <VirtualKeyboard
                onChange={handleChange}
                onEnter={handleOnEnter}
                customStyle={{
                    bottom: isPortraitView ? '4rem' : 0,
                    transform: isPortraitView ? 'scale(1.72)' : 'scale(1.62)',
                }}
            />
            <CircularProgressbar loading={loading} />
            <CustomSnackbar
                open={error.error}
                message={error.errorMessage}
                severity="error"
            />
        </>
    );
};

export default ProductFields;
