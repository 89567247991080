export const cartContainer = {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#ffffff',
    borderTop: '5px solid rgb(204 51 51)',
    padding: '0 2rem',
    paddingTop: '1.5rem',
};

export const iconButtonStyle = {
    position: 'relative',
    bottom: '38px',
    transform: 'translateX(calc(50% - 56px))',
    left: '50%',
};

export const dataContainer = {
    marginBottom: '2rem',
};

export const actionButtons = {
    position: 'fixed',
    bottom: '1rem',
    left: 0,
    right: 0,
    margin: '0 2rem',
};

export const cartLabel = {
    fontWeight: 700,
    marginBottom: '1rem',
};

export const customerNameTextField = {
    border: 'solid #cc3333 3px',
    borderRadius: '12px',
    padding: '0.5rem',
    lineHeight: 1.5,
    fontSize: '1.8rem',
    textAlign: 'center',
};

export const backdropStyle = {
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: ' #fff',
    zIndex: 1201,
};
