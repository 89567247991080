import { useDispatch } from 'react-redux';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box, CardActionArea } from '@mui/material';
import { beautifyPrice } from '../../../shared/utils/helpers';
import ProductCardButtonContent from './ProductCardButtonContent';
import { markProductAsOrdered } from '../../../store/slices/productPageSlice';

export default function ProductCard(props) {
    const dispatch = useDispatch();
    const handleClick = () => {
        // if (!props.product.isOrdered) {
        dispatch(
            markProductAsOrdered({
                menuId: props.product?.menuId,
                isOrdered: true,
            }),
        );
        props.onHandleClick(props.product);
        // }
    };
    return (
        <Card
            onClick={handleClick}
            sx={{
                maxWidth: 250,
                minWidth: 170,
                ...(props.product?.isOrdered && {
                    boxShadow: '0px 3px 8px 4px rgb(2 2 2 / 21%)',
                }),
            }}
        >
            <CardActionArea>
                {!props.product?.productId && (
                    <ProductCardButtonContent {...props} />
                )}
                {props.product?.productId && <ProductCardContent {...props} />}
            </CardActionArea>
        </Card>
    );
}

const ProductCardContent = ({ onHandleClick, product }) => {
    return (
        <Box>
            <Box sx={{ position: 'relative' }}>
                <CardMedia
                    component="img"
                    height="100"
                    src={`/resources/assets/${product?.image}`}
                    alt={product?.name}
                />
                {/* <PromoIndicator /> */}
            </Box>
            <CardContent
                sx={{
                    height: 93,
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '1rem 4px',
                }}
            >
                <Typography
                    variant="subtitle1"
                    gutterBottom
                    color="text.secondary"
                    sx={{ lineHeight: 1.5 }}
                    color="primary"
                >
                    {product?.name}
                </Typography>
                {/* <Divider /> */}
                {product?.itemPrice && (
                    <Typography
                        gutterBottom
                        variant="subtitle2"
                        sx={{
                            fontWeight: 700,
                            marginTop: '.2rem',
                            fontSize: '1rem',
                        }}
                        color="primary"
                        component="div"
                    >
                        {beautifyPrice(parseInt(product?.itemPrice))}
                    </Typography>
                )}
            </CardContent>
        </Box>
    );
};
