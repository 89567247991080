import { createSlice } from '@reduxjs/toolkit';

export const settingsReducer = createSlice({
    name: 'orderItems',
    initialState: {
        items: [],
        totalAmount: 0,
        customerNameValue: '',
        counter: 0, // used to get the totalAmount everytime it changed value
    },
    reducers: {
        setOrderItem: (state, { payload }) => {
            state.items = payload;
            state.counter += 1;
            return state;
        },
        addOrderItem: (state, { payload }) => {
            state.counter += 1;
            const duplicateOrder = state.items.find(
                (item) => item.menuId === payload?.menuId,
            );
            if (duplicateOrder) {
                state.items = state.items.map((item) =>
                    item.menuId === payload.menuId
                        ? { ...item, quantity: item.quantity + 1 }
                        : item,
                );
                return state;
            }

            const newPayload = {
                ...payload,
                ...(payload?.itemPrice && {
                    itemPrice: parseInt(payload.itemPrice),
                }),
                quantity: 1,
                isOrdered: true,
            };
            state.items.push(newPayload);
            return state;
        },
        addQuantity: (state, { payload }) => {
            state.items.forEach((item, index, arr) => {
                if (item.productId === payload) {
                    arr[index].quantity += 1;
                }
            });
            state.counter += 1;
            return state;
        },
        subtractQuantity: (state, { payload }) => {
            state.items.forEach((item, index, arr) => {
                if (item.productId === payload && item.quantity > 0) {
                    arr[index].quantity -= 1;
                }
            });
            state.counter += 1;
            return state;
        },
        getTotalAmount: (state) => {
            const totalSum = state.items.map(
                (item) => item?.itemPrice * item.quantity,
            );
            const totalAmount = totalSum.reduce(
                (prev, current) => prev + current,
                0,
            );
            state.totalAmount = totalAmount;
            return state;
        },
        removeItem: (state, { payload }) => {
            state.items = state.items.filter(
                (item) => item?.menuId !== payload,
            );
            state.counter += 1;
            return state;
        },
        setCustomerNameValue: (state, { payload }) => {
            switch (payload) {
                case 'DELETE':
                    state.customerNameValue = '';
                    break;

                case 'BACKSPACE':
                    state.customerNameValue = String(
                        state.customerNameValue,
                    ).slice(0, -1);
                    break;
                case 'SPACE': {
                    state.customerNameValue = `${
                        state.customerNameValue
                    }${' '}`;
                    break;
                }

                case 'ENTER':
                    break;
                default:
                    state.customerNameValue = `${state.customerNameValue}${payload}`;
                    break;
            }
            return state;
        },
        clearCart: (state) => {
            state.counter = 0;
            state.totalAmount = 0;
            state.items = [];
            return state;
        },
    },
});

export const {
    addQuantity,
    setOrderItem,
    addOrderItem,
    subtractQuantity,
    getTotalAmount,
    removeItem,
    setCustomerNameValue,
    clearCart,
} = settingsReducer.actions;
export default settingsReducer.reducer;
