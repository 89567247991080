import { Stack, styled, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { beautifyPrice } from '../../../utils/helpers';

const container = {
    flexDirection: 'row',
    height: '3rem',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
};

const CustomStack = styled(Stack)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
}));

const CartHeader = ({ totalAmount }) => {
    return (
        <CustomStack sx={container} direction={'row'} spacing={3}>
            {' '}
            <Box sx={{ width: '50%' }}>
                <Typography variant="h5" align="center">
                    MY ORDER
                </Typography>{' '}
            </Box>
            <Box sx={{ width: '50%' }}>
                <Typography variant="h5" align="center">
                    TOTAL {beautifyPrice(totalAmount)}
                </Typography>{' '}
            </Box>
        </CustomStack>
    );
};

export default CartHeader;
