import { useState } from 'react';

const useApiHook = (requestMethod, getNestedValue) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({ error: false, errorMessage: '' });

    const request = async (...args) => {
        setLoading(true);
        const response = await requestMethod(...args);
        console.log('response...................', response);
        setLoading(false);

        if (!response?.ok) {
            return setError({ error: true, errorMessage: response?.data });
        }
        setError(false);
        setData(response?.data);
    };

    return {
        data: getNestedValue ? getNestedValue(data) : data,
        error,
        loading,
        request,
    };
};

export default useApiHook;
