import {
    DialogContentText,
    Typography,
    Button,
    DialogActions,
} from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import CustomButton from '../../../CustomButton';
const ConfirmationDialogBody = ({
    onCloseDialog,
    confirmLabel = 'Ok',
    confirmationMessage,
    onSubmit,
}) => {
    return (
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {confirmationMessage}
            </DialogContentText>
            <DialogActions>
                <CustomButton onClick={onCloseDialog}>Cancel</CustomButton>
                <CustomButton onClick={onSubmit} autoFocus>
                    {confirmLabel}
                </CustomButton>
            </DialogActions>
        </DialogContent>
    );
};

export default ConfirmationDialogBody;
