import {
    Grid,
    Box,
    Fab,
    Stack,
    Typography,
    InputBase,
    Backdrop,
} from '@mui/material';

import { motion, AnimatePresence, useAnimationControls } from 'framer-motion';

import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useEffect, useRef, useState } from 'react';
import {
    actionButtons,
    cartContainer,
    cartLabel,
    customerNameTextField,
    dataContainer,
    iconButtonStyle,
    backdropStyle,
} from './cart-style';
import CustomButton from '../CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import {
    getTotalAmount,
    setCustomerNameValue,
} from '../../../store/slices/OrderItemSlice';
import useNavigateWithQueryString from '../../hooks/useNavigateWithDefaultQueryString';
import JdOrderItem from '../order-summary/components/jd-order-item';
import { beautifyPrice } from '../../utils/helpers';
import VirtualKeyboard from '../virtual-keyboard';
import { setCurrentInputField } from '../../../store/slices/keyboardSlice';
import { prepareVoucher } from '../../../pages/product-fields/product-field.service';
import { productFieldValidation } from '../../../pages/product-fields/utils/helper';
import useAgentDetails from '../../hooks/useAgentDetails';
import useApiHook from '../../hooks/useApiHook';
import {
    setErrorMessage,
    setFieldValue,
} from '../../../store/slices/productFieldsSlice';
import CircularProgressbar from '../CircularProgressbar';
import ExpandButton from '../ExpandButton';
import { AddBox } from '@mui/icons-material';

const DeliCart = () => {
    const { items, counter, totalAmount, customerNameValue } = useSelector(
        ({ orderItems }) => orderItems,
    );

    const reduxStates = useSelector((store) => store);

    const dispatch = useDispatch();
    const navigate = useNavigateWithQueryString();
    const controls = useAnimationControls();
    const agentDetails = useAgentDetails();
    const [expanded, setExpanded] = useState(false);
    const containerRef = useRef();

    const [errorMessage, setErrorMessage] = useState('');

    const {
        loading,
        request: prepareVoucherRequest,
        data: response,
        error,
    } = useApiHook(prepareVoucher);

    useEffect(() => {
        dispatch(getTotalAmount());
    }, [counter]);

    useEffect(() => {
        dispatch(
            setFieldValue({ name: 'customer_name', value: customerNameValue }),
        );
    }, [customerNameValue]);

    useEffect(() => {
        const isPrepareVoucherSuccess =
            typeof response === 'object' &&
            Object.keys(response).includes('prepareProductVoucherResponse');
        if (isPrepareVoucherSuccess)
            navigate('/order-complete', { replace: true });
    }, [response]);

    useEffect(() => {
        if (errorMessage) {
            controls.start({
                boxShadow: '0px 0px 13px 5px #ec0c0c',
                borderRadius: 14,
                transition: { duration: 1, yoyo: Infinity },
            });
        } else {
            controls.stop();
        }
    }, [errorMessage]);

    useEffect(() => {
        dispatch(setCurrentInputField('customer_name'));
    }, []);

    const onHandleChange = (key) => {
        dispatch(setCustomerNameValue(key));
    };

    const handleOnFocus = ({ target }) => {
        setExpanded(true);
        // dispatch(setCurrentInputField(target.name));
    };

    const onCompleteOrder = () => {
        const { productFields } = reduxStates.productFieldPage;
        const { currentInputField } = reduxStates.keyboard;
        const error = productFieldValidation(productFields, currentInputField);

        if (error.errorMessage) {
            // dispatch(setErrorMessage(error));
            setErrorMessage(error.errorMessage);
            return;
        }
        prepareVoucherRequest(agentDetails, reduxStates);
    };
    return (
        // sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        // open={true}
        <Box
            sx={expanded ? backdropStyle : {}}
            component={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
                duration: 1,
            }}
        >
            <Box
                sx={cartContainer}
                component={motion.div}
                initial={{ height: !expanded ? 400 : '80vh' }}
                animate={{ height: expanded ? '80vh' : 400 }}
                transition={{ duration: 1 }}
                ref={containerRef}
            >
                {/* <Fab
                color="secondary"
                aria-label="expand"
                // size="large"
                sx={iconButtonStyle}
                onClick={() => setExpanded(!expanded)}
            >
                <Box
                    component={motion.div}
                    animate={{ rotate: !expanded ? 0 : 180 }}
                    transition={{ duration: 1 }}
                >
                    <ArrowUpIcon />
                </Box>
            </Fab> */}
                <ExpandButton
                    onClick={() => setExpanded(!expanded)}
                    expand={expanded}
                    disabled={!items.length ? true : false}
                />

                {items.length > 0 && (
                    <Grid sx={dataContainer} container spacing={4}>
                        <Grid item sx={{ paddingTop: 0 }} sm={8} xs={12}>
                            <Typography
                                sx={cartLabel}
                                color={'primary'}
                                variant="h5"
                            >
                                My Order
                            </Typography>
                            <Stack direction={'column'} spacing={2}>
                                {items
                                    .slice(0, !expanded ? 3 : items.length)
                                    .map((data, i) => (
                                        <JdOrderItem
                                            {...data}
                                            key={data?.name + i}
                                        />
                                    ))}
                                {items.length > 3 && !expanded && (
                                    <CustomButton
                                        variant="text"
                                        color="primary"
                                        size="small"
                                        sx={{ marginTop: 0 }}
                                        onClick={() => setExpanded(!expanded)}
                                    >
                                        <Typography
                                            textAlign={'center'}
                                            variant="subtitle1"
                                            sx={{ fontWeight: 700 }}
                                            color="primary"
                                        >
                                            +{items.length - 3}{' '}
                                            {items.length - 3 > 1
                                                ? 'items'
                                                : 'item'}
                                        </Typography>
                                    </CustomButton>
                                )}
                            </Stack>
                        </Grid>

                        <Grid item sx={{ paddingTop: 0 }} sm={4} xs={12}>
                            <Stack direction="column" spacing={4}>
                                <Box>
                                    <Typography
                                        sx={{
                                            ...cartLabel,
                                            marginBottom: '.2rem',
                                        }}
                                        color={'primary'}
                                        variant="h5"
                                        textAlign={'center'}
                                    >
                                        Total Payment
                                    </Typography>
                                    <Box
                                        sx={{
                                            border: 'solid #cc3333 3px',
                                            borderRadius: '12px',
                                            padding: '0.5rem',
                                        }}
                                    >
                                        <Typography
                                            color="primary"
                                            variant="h4"
                                            align="center"
                                        >
                                            {beautifyPrice(totalAmount, true)}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ marginTop: '.5rem' }}>
                                    <Typography
                                        sx={{
                                            ...cartLabel,
                                            marginBottom: '.2rem',
                                        }}
                                        color={'primary'}
                                        variant="h5"
                                        textAlign={'center'}
                                    >
                                        Your Name Please?
                                    </Typography>
                                    <motion.div
                                        initial={{
                                            borderRadius: 14,
                                            boxShodow: 'none',
                                        }}
                                        animate={controls}
                                    >
                                        <InputBase
                                            sx={customerNameTextField}
                                            name="customer_name"
                                            value={customerNameValue}
                                            onFocus={handleOnFocus}
                                            color="primary"
                                            multiline
                                            fullWidth
                                            inputProps={{
                                                style: {
                                                    textAlign: 'center',
                                                    color: '#c62828',
                                                },
                                            }}
                                        />
                                    </motion.div>

                                    <AnimatePresence>
                                        {errorMessage && (
                                            <Box
                                                component={motion.div}
                                                animate={{
                                                    scale: [1, 1.1, 1],
                                                }}
                                                transition={{
                                                    duration: 0.5,
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        textAlign: 'center',
                                                        fontWeight: 700,
                                                    }}
                                                    color="primary"
                                                >
                                                    {errorMessage}
                                                </Typography>
                                            </Box>
                                        )}
                                    </AnimatePresence>
                                </Box>
                            </Stack>
                        </Grid>
                    </Grid>
                )}
                {!items.length && (
                    <Typography align="center" variant="h5" gutterBottom>
                        Your cart is empty
                    </Typography>
                )}
                {items.length > 0 && (
                    <Stack
                        sx={{
                            marginTop: expanded ? '6rem' : '.5rem',
                            // ...actionButtons,
                            // ...(expanded && { bottom: '16rem' }),
                        }}
                        direction="row"
                        spacing={2}
                        justifyContent="center"
                    >
                        <CustomButton
                            size="large"
                            color="primary"
                            variant="outlined"
                        >
                            Cancel Order
                        </CustomButton>
                        <CustomButton
                            size="large"
                            variant="contained"
                            color="primary"
                            disabled={totalAmount <= 0}
                            onClick={onCompleteOrder}
                        >
                            Complete Order
                        </CustomButton>
                    </Stack>
                )}
                <AnimatePresence>
                    {expanded && (
                        <Box
                            component={motion.div}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{
                                delay: 0.5,
                                ease: 'linear',
                                duration: 0.5,
                            }}
                            exit={{
                                opacity: 0,
                                transition: 'ease-out',
                                duration: 0.4,
                            }}
                        >
                            <VirtualKeyboard
                                onChange={onHandleChange}
                                onEnter={onCompleteOrder}
                                useValidationJSON
                                customStyle={{
                                    bottom: '4rem',
                                    transform: 'scale(1.72)',
                                }}
                            />
                        </Box>
                    )}
                </AnimatePresence>
                <CircularProgressbar loading={loading} />
            </Box>
        </Box>
    );
};

export default DeliCart;
